import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="p-6 max-w-4xl mx-auto text-left text-white">
      <h1 className="text-4xl font-bold mb-6">Politique de Confidentialité</h1>

      <p className="mb-4">
        Chez PicOrganizer, nous nous engageons à protéger votre vie privée.
        Cette politique de confidentialité explique comment nous collectons,
        utilisons et partageons vos informations personnelles lorsque vous
        utilisez nos services.
      </p>

      <h2 className="text-2xl font-semibold mb-4">
        1. Informations que nous collectons
      </h2>
      <p className="mb-4">
        Nous collectons les types d'informations personnelles suivants :
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>Adresse e-mail</li>
        <li>Nom et prénom</li>
        <li>Mot de passe</li>
        <li>
          Informations de paiement (gérées par Stripe, non stockées par nous)
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4">
        2. Comment nous collectons les informations
      </h2>
      <p className="mb-4">
        Nous collectons des informations personnelles lorsque vous :
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>Créez un compte sur notre plateforme</li>
        <li>Souscrivez à l'un de nos abonnements payants</li>
      </ul>
      <p className="mb-4">
        Nous utilisons également l'outil d'analyse de Vercel pour collecter des
        données d'utilisation, mais aucune information personnelle de navigation
        n'est stockée.
      </p>

      <h2 className="text-2xl font-semibold mb-4">
        3. Comment nous utilisons vos informations
      </h2>
      <p className="mb-4">
        Les informations personnelles que nous collectons sont utilisées pour :
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>Traiter les paiements pour les abonnements</li>
        <li>Authentifier votre connexion</li>
        <li>Envoyer des newsletters (si vous y avez souscrit)</li>
        <li>Améliorer votre expérience avec du contenu personnalisé</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4">
        4. Partage de vos informations
      </h2>
      <p className="mb-4">
        Nous partageons vos informations personnelles avec des tiers uniquement
        dans les circonstances suivantes :
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>
          <strong>Stripe :</strong> Pour traiter les paiements en toute
          sécurité.
        </li>
        <li>
          <strong>Vercel :</strong> Pour héberger notre plateforme et fournir
          des services analytiques.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4">5. Sécurité des données</h2>
      <p className="mb-4">
        Nous prenons la sécurité des données au sérieux et mettons en œuvre des
        mesures appropriées telles que le cryptage pour protéger vos
        informations personnelles. Les informations de paiement sont sécurisées
        et traitées par Stripe, conformément aux protocoles de sécurité standard
        de l'industrie.
      </p>

      <h2 className="text-2xl font-semibold mb-4">6. Vos droits</h2>
      <p className="mb-4">
        Vous avez le droit d'accéder, de modifier ou de supprimer vos
        informations personnelles. Vous pouvez le faire via votre page de profil
        sur notre plateforme.
      </p>

      <h2 className="text-2xl font-semibold mb-4">
        7. Conservation des données
      </h2>
      <p className="mb-4">
        Nous conservons vos informations personnelles pendant une période de 6
        ans. Cela est nécessaire pour se conformer aux exigences légales et
        réglementaires.
      </p>

      <h2 className="text-2xl font-semibold mb-4">8. Contactez-nous</h2>
      <p className="mb-4">
        Si vous avez des questions concernant cette politique de
        confidentialité, veuillez nous contacter via le formulaire de contact
        sur notre site ou en nous envoyant un e-mail à
        contactpicorganizer@gmail.com. Nous nous efforçons de répondre à toutes
        les demandes dans un délai de 72 heures.
      </p>

      <h2 className="text-2xl font-semibold mb-4">
        9. Modifications de cette politique de confidentialité
      </h2>
      <p className="mb-4">
        Nous pouvons mettre à jour cette politique de confidentialité de temps à
        autre. Toute modification sera publiée sur cette page, et si les
        changements sont importants, nous fournirons un avis plus visible (comme
        l'ajout d'un avis sur la page d'accueil ou l'envoi d'une notification
        par e-mail).
      </p>
    </div>
  );
};

export default PrivacyPolicy;
