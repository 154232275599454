import React, { useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// Load the Stripe object
const stripePromise = loadStripe(
  "pk_test_51OqBy9GI3hHXERtK2QFdWsZRLz3AG5TvFVqhOvZS92tgV5UbAIijBuf7x7EiEmv6I5P4DcsriT0yObuy7wil35ET008i1nWyqz"
);

const StripeBuyButton = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/buy-button.js";
    script.async = true;
    document.body.appendChild(script);

    // Check if the stripe-buy-button element is defined after the script loads
    script.onload = () => {
      if (window.StripeBuyButton) {
        const stripeBuyButton = document.querySelector("stripe-buy-button");
        if (stripeBuyButton) {
          stripeBuyButton.initialize();
        }
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <stripe-buy-button
        buy-button-id="buy_btn_1PhFzmGI3hHXERtK1g9Yn0dr"
        publishable-key="pk_test_51OqBy9GI3hHXERtK2QFdWsZRLz3AG5TvFVqhOvZS92tgV5UbAIijBuf7x7EiEmv6I5P4DcsriT0yObuy7wil35ET008i1nWyqz"
      ></stripe-buy-button>
    </div>
  );
};

const StripeWrapper = () => (
  <Elements stripe={stripePromise}>
    <StripeBuyButton />
  </Elements>
);

export default StripeWrapper;
