import React, { useEffect } from "react";
import ModalUnsubscribe from "../../Modals/ModalUnsubscribe";
import moment from "moment";
import { useAuth } from "../../auth/AuthProvider";
import { getInvoicesByUserId } from "../../../services/stripe.services";
import InvoiceCard from "./InvoiceCard";

const YourPlan = ({ userData }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { userId } = useAuth();
  const [invoices, setInvoices] = React.useState([]);

  const openModalUnsubscribe = () => {
    setIsOpen(true);
  };

  const closeModalUnsubscribe = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const fetchInvoices = async () => {
      const _invoices = await getInvoicesByUserId(userId);
      setInvoices(_invoices.invoices);
    };
    fetchInvoices();
  }, [userId]);

  return (
    <div className="flex flex-col gap-5 ">
      <div className="flex flex-col gap-3">
        <h1 className="text-white text-2xl mb-2">Votre plan</h1>
        <p className="text-white text-2xl text-center mt-5 mb-5">
          {userData.subscription.planType?.charAt(0).toUpperCase() +
            userData.subscription.planType.slice(1)}
        </p>

        <p className="text-white">
          Prochain renouvelement :{" "}
          {moment(userData.subscription.endDate).format("LL")}
        </p>
        <p className="text-white">
          En cas de résiliation, ton abonnement restera actif jusqu'au :{" "}
          {moment(userData.subscription.endDate).format("LL")}
        </p>
        <p
          className="text-white underline hover:cursor-pointer"
          onClick={openModalUnsubscribe}
        >
          résilier
        </p>
        <ModalUnsubscribe
          isOpen={isOpen}
          userData={userData}
          userId={userId}
          onClose={closeModalUnsubscribe}
        />
      </div>
      <div>
        <h2 className="text-white text-2xl mb-2">Historique de facturation</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {invoices.map((invoice) => (
            <InvoiceCard key={invoice.id} invoice={invoice} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default YourPlan;
