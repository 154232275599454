import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import Avatar from "../Avatar/Avatar";
import logo from "../../images/logo.png";
import { useAuth } from "../auth/AuthProvider";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { token, handleLogout, user } = useAuth(); 

  const handleNavigation = (path, hash) => {
    navigate(path);
    setTimeout(() => {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex w-full h-auto justify-center items-center pt-7">
      <div className="bg-white rounded-full w-3/4 p-2 flex justify-between items-center shadow-slate-100 relative">
        <div className="flex flex-1 justify-start pl-5 h-10">
          <img
            src={logo}
            alt="Logo"
            className="cursor-pointer"
            onClick={() => handleNavigation("/", "home")}
          />
        </div>
        <div className="hidden lg:flex flex-1 justify-center gap-10 font-bold text-xl">
          <Link to="/" onClick={() => handleNavigation("/", "home")}>
            Accueil
          </Link>
          <Link to="/" onClick={() => handleNavigation("/", "product")}>
            Produit
          </Link>
          <Link to="/" onClick={() => handleNavigation("/", "price")}>
            Tarifs
          </Link>
          <Link to="/contact" onClick={() => handleNavigation("/", "contact")}>
            Contact
          </Link>
        </div>
        <div className="hidden lg:flex flex-1 justify-end gap-5">
          {!token && (
            <Link className="h-auto text-black p-2 rounded-lg hover:underline" to="/login">
              Se connecter
            </Link>
          )}

          {token ? (
            <div>
              <Avatar user={user} handleLogout={handleLogout} />
            </div>
          ) : (
            <Link
              className="mr-5 bg-gradient-to-r from-gray-700 to-gray-800 h-auto text-white p-2 rounded-lg"
              to="/register"
            >
              S'inscrire
            </Link>
          )}
        </div>

        {/* Hamburger Menu Button for Small Screens */}
        <div className="lg:hidden flex items-center justify-end">
          <button onClick={toggleMenu} className="text-black focus:outline-none">
            <FontAwesomeIcon icon={isOpen ? faTimes : faBars} size="lg" />
          </button>
        </div>

        {/* Dropdown Menu for Small Screens */}
        {isOpen && (
          <div className="lg:hidden absolute top-full left-0 right-0 bg-white rounded-lg shadow-lg mt-2 py-4 z-50">
            <div className="flex flex-col items-center gap-5 font-bold text-xl">
              <Link
                to="/"
                onClick={() => {
                  toggleMenu();
                  handleNavigation("/", "home");
                }}
              >
                Accueil
              </Link>
              <Link
                to="/"
                onClick={() => {
                  toggleMenu();
                  handleNavigation("/", "product");
                }}
              >
                Produit
              </Link>
              <Link
                to="/"
                onClick={() => {
                  toggleMenu();
                  handleNavigation("/", "price");
                }}
              >
                Tarifs
              </Link>
              <Link
                to="/contact"
                onClick={() => {
                  toggleMenu();
                  handleNavigation("/", "contact");
                }}
              >
                Contact
              </Link>
              {!token && (
                <Link
                  className="h-auto text-black p-2 rounded-lg hover:underline"
                  to="/login"
                  onClick={toggleMenu}
                >
                  Se connecter
                </Link>
              )}
              {token ? (
                <Avatar user={user} handleLogout={handleLogout} />
              ) : (
                <Link
                  className="bg-gradient-to-r from-gray-700 to-gray-800 h-auto text-white p-2 rounded-lg"
                  to="/register"
                  onClick={toggleMenu}
                >
                  S'inscrire
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
