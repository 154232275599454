import React from "react";

const Cancel = () => {
  return (
    <div className="min-h-screen flex items-center justify-center ">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center">
        <h1 className="text-4xl font-bold text-red-600 mb-4">
          Transaction annulée
        </h1>
        <p className="text-lg text-gray-700 mb-8">
          Votre transaction a été annulée. Si vous avez besoin d'aide, veuillez
          contacter le support.
        </p>
        <div className="flex justify-center">
          <a
            href="/"
            className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-300"
          >
            Retour à l'accueil
          </a>
        </div>
      </div>
    </div>
  );
};

export default Cancel;
