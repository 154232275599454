import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
// const API_URL = process.env.REACT_APP_API_URL_LOCAL;


export const createSession = async (plan, customerId) => {
  try {
    console.log("plan", plan);

    const req = await axios.post(
      `${API_URL}/sub/create-subscription-checkout`,
      {
        plan,
        customerId,
      }
    );

    console.log(req);
    return req;
  } catch (error) {
    console.log(
      "Error creating session:",
      error.response ? error.response.data : error.message
    );
    return null; // Retourne null en cas d'erreur
  }
};

export const payementSuccess = async ({ userId, sessionId }) => {
  console.log("userId", userId);
  console.log("sessionId", sessionId);
  try {
    const req = await axios.post(`${API_URL}/sub/payment-success`, {
      userId,
      sessionId,
    });

    console.log(req);
    return req;
  } catch (error) {
    console.log(
      "Error creating session:",
      error.response ? error.response.data : error.message
    );
    return null; // Retourne null en cas d'erreur
  }
};

export const cancelSubscription = async (userId) => {
  try {
    const response = await axios.post(`${API_URL}/sub/cancel-subscription`, {
      userId,
    });

    console.log("API response:", response);
    return response;
  } catch (error) {
    console.error(
      "Error canceling subscription:",
      error.response ? error.response.data : error.message
    );
    return null;
  }
};
export const updateSubscription = async (userId, newPlan) => {
  try {
    const req = await axios.post(`${API_URL}/sub/update-subscription`, {
      userId,
      newPlan,
    });
    console.log(req);
    return req;
  } catch (error) {
    console.log(
      "Error creating session:",
      error.response ? error.response.data : error.message
    );
    return null;
  }
};

export const getInvoicesByUserId = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/sub/get-invoices/${userId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching invoices:",
      error.response ? error.response.data : error.message
    );
    return null;
  }
};
