import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Swal from "sweetalert2";
import {
  deleteUserById,
  getUserById,
  updateUserById,
} from "../../services/user.services";
import { useAuth } from "../auth/AuthProvider";
import Stats from "./Stats/Stats";
import YourPlan from "./Plan/YourPlan";
import ButtonComponent from "../elements/Button";
import { cancelSubscription } from "../../services/stripe.services";

const tabs = [
  { id: "profile", label: "Profil" },
  { id: "stats", label: "Historique et Statistique" },
  { id: "plan", label: "Abonnements" },
];

const Profil = () => {
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [userData, setUserData] = useState(null);
  const [formData, setFormData] = useState({
    username: user.username,
    lastname: user.lastname,
    email: user.email,
    currentPassword: "",
    newPassword: "",
  });

  const initials = user?.username.slice(0, 2).toUpperCase();

  const fetchUserData = async () => {
    try {
      const userData = await getUserById(user.userId);
      setUserData(userData);
      setFormData({
        username: userData.username,
        lastname: userData.lastname,
        email: userData.email,
        currentPassword: "",
        newPassword: "",
      });
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateUserById(user.userId, formData);
      Swal.fire({
        title: "Succès!",
        text: "Profil mis à jour avec succès",
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        fetchUserData();
      });
    } catch (error) {
      console.error("Failed to update user:", error);
      Swal.fire({
        title: "Erreur!",
        text:
          error.response?.data?.message || "Échec de la mise à jour du profil",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const { handleLogout } = useAuth();

  const deleteMyAccount = async () => {
    const result = await Swal.fire({
      title: "Êtes-vous sûr?",
      text: "Cette action est irréversible. Votre compte sera définitivement supprimé.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Oui, supprimer",
      cancelButtonText: "Annuler",
    });

    if (result.isConfirmed) {
      try {
        await deleteUserById(user.userId);
        await cancelSubscription(user.userId);
        Swal.fire({
          title: "Succès!",
          text: "Votre compte a été supprimé avec succès",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          handleLogout();
          window.location.href = "/";
        });
      } catch (error) {
        Swal.fire({
          title: "Erreur!",
          text: "Une erreur s'est produite lors de la suppression de votre compte. Veuillez réessayer.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  if (!userData) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-lg font-semibold">Chargement...</div>
      </div>
    );
  }

  return (
    <div className="text-black min-h-screen flex flex-col items-center p-4 md:p-10">
      <div className=" rounded-lg w-full max-w-5xl">
        <nav className="flex justify-around border-b border-gray-300 ">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`relative p-2  text-white font-medium mb-2 ${
                activeTab === tab.id ? "" : ""
              }`}
              style={{ WebkitTapHighlightColor: "transparent" }}
            >
              {activeTab === tab.id && (
                <motion.span
                  layoutId="bubble"
                  className="absolute inset-0 z-10 bg-gray-700   rounded-lg"
                  transition={{ type: "spring", bounce: 0.2, duration: 0.6 }}
                />
              )}
              <span className="relative z-20">{tab.label}</span>
            </button>
          ))}
        </nav>

        <div className="p-8">
          {activeTab === "profile" && (
            <motion.div
              key="profile"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
            >
              <div className="flex items-center space-x-4 mb-6">
                <div className="bg-gray-200 p-4 rounded-full">{initials}</div>
                <div>
                  <h1 className="text-2xl font-bold text-white">
                    {userData.username}
                  </h1>
                  <p className=" text-white">{userData.lastname}</p>
                </div>
              </div>

              <form className="space-y-4" onSubmit={handleSubmit}>
                <input
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  placeholder="Nom d'utilisateur"
                />
                <input
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  name="lastname"
                  value={formData.lastname}
                  onChange={handleChange}
                  placeholder="Nom de famille"
                />
                <input
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                />
                <input
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  type="password"
                  name="currentPassword"
                  value={formData.currentPassword}
                  onChange={handleChange}
                  placeholder="Mot de passe actuel"
                />
                <input
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  type="password"
                  name="newPassword"
                  value={formData.newPassword}
                  onChange={handleChange}
                  placeholder="Nouveau mot de passe"
                />

                <ButtonComponent color={"white"} type="submit">Mettre à jour</ButtonComponent>
                <p onClick={deleteMyAccount} className="text-white">
                  Supprimer mon compte
                </p>
              </form>
            </motion.div>
          )}

          {activeTab === "stats" && (
            <motion.div
              key="stats"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
            >
              <Stats />
            </motion.div>
          )}

          {activeTab === "plan" && (
            <motion.div
              key="plan"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              className="flex flex-col space-y-6"
            >
              <YourPlan userData={userData}></YourPlan>
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profil;
