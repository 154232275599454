import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
// const API_URL = process.env.REACT_APP_API_URL_LOCAL;


export const checkPlan = async (userId, number_of_photos) => {
  try {
    const response = await axios.post(
      `${API_URL}/transfer/${userId}/check-plan`,
      { number_of_photos }
    );
    return response.data;
  } catch (error) {
    console.error("Error checking plan:", error);
    throw error;
  }
};

export const addTransferToUser = async ({
  userId,
  number_of_photos,
  filter,
  destFile,
  sourceFile,
  allowMail,
}) => {
  try {
    const response = await axios.post(
      `${API_URL}/transfer/${userId}/add-transfer`,
      {
        number_of_photos,
        filter,
        destFile,
        sourceFile,
        allowMail,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error adding transfer to user:", error);
    throw error;
  }
};

export const getTransfersByUserId = async (userId) => {
  try {
    const response = await axios.get(
      `${API_URL}/transfer/${userId}/get-transfers`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching transfers by user ID:", error);
    throw error;
  }
};
