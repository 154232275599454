import React from "react";
import ProductFeatures from "./ProductFeatures";

const Product = () => {
  return (
    <div className="flex items-center justify-center sm:min-h-screen h-auto p-10 flex-col">
      <ProductFeatures></ProductFeatures>
    </div>
  );
};

export default Product;
