import React, { useEffect, useState } from "react";
import { useAuth } from "../../auth/AuthProvider";
import { getTransfersByUserId } from "../../../services/transfer.services";
import moment from "moment";
import "moment/locale/fr"; // Import de la locale française
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

// Configurer Moment.js pour utiliser la locale française
moment.locale("fr");

const transferLabels = {
  simple: "Transfert simple",
  format: "Trier par format",
  date: "Trier par date",
  formatThenDate: "Trier par format puis par date",
  dateThenFormat: "Trier par date puis par format",
  location: "Trier par localisation",
  makeModel: "Trier par marque et modèle",
  focalLength: "Trier par distance focale",
  iso: "Trier par ISO",
  ia: "Trier par IA",
};

const groupTransfersByDate = (transfers) => {
  return transfers.transfers.reduce((acc, transfer) => {
    const dateKey = moment(transfer.date).format("YYYY-MM-DD");
    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }
    acc[dateKey].push(transfer);
    return acc;
  }, {});
};

const Stats = () => {
  const { user, userId } = useAuth();
  const [groupedTransfers, setGroupedTransfers] = useState({});
  const [openDates, setOpenDates] = useState({}); // État pour suivre les sections ouvertes

  useEffect(() => {
    const fetchTransfers = async () => {
      try {
        const transfers = await getTransfersByUserId(userId);
        const grouped = groupTransfersByDate(transfers);
        setGroupedTransfers(grouped);
      } catch (error) {
        console.error("Failed to fetch transfers:", error);
      }
    };

    if (userId) {
      fetchTransfers();
    }
  }, [userId]);

  const toggleDate = (date) => {
    setOpenDates((prevState) => ({
      ...prevState,
      [date]: !prevState[date],
    }));
  };

  return (
    <div className="space-x-4 mb-6 text-white">
      <h1 className="text-3xl font-bold mb-6">Vos statistiques de transfert</h1>

      {Object.keys(groupedTransfers).length === 0 ? (
        <p className="text-lg">Aucun transfert trouvé.</p>
      ) : (
        // Trier les dates dans l'ordre décroissant
        Object.keys(groupedTransfers)
          .sort((a, b) => moment(b).diff(moment(a)))
          .map((date) => (
            <div key={date} className="mb-8">
              <h2
                className="text-2xl font-semibold mb-4 cursor-pointer flex items-center justify-between"
                onClick={() => toggleDate(date)}
              >
                {moment(date).format("LL")} {/* Affiche la date en français */}
                <FontAwesomeIcon
                  icon={openDates[date] ? faChevronUp : faChevronDown}
                  className="ml-2"
                />
              </h2>
              {openDates[date] && ( // Afficher les cartes si la date est ouverte
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {groupedTransfers[date].map((transfer) => (
                    <div
                      key={transfer._id}
                      className="bg-gradient-to-r from-gray-100 to-gray-300 text-black rounded-lg shadow-lg p-4 flex flex-col justify-between"
                    >
                      <div className="mb-4">
                        <h3 className="text-xl font-semibold mb-2">
                          {transferLabels[transfer.filter] ||
                            transfer.filter.toUpperCase()}
                        </h3>
                        <p className="text-sm text-gray-900  mb-2">
                          {moment(transfer.date).format("LT")}{" "}
                          {/* Heure locale */}
                        </p>

                        {transfer.sourceFile && transfer.destFile && (
                          <div className="flex flex-col gap-2 mb-2">
                            <div className="">
                              Dossier source :{"  "}
                              <span className="font-bold ">
                                {transfer.sourceFile}
                              </span>{" "}
                            </div>
                            <div>
                              Dossier destination :{" "}
                              <span className="font-bold">
                                {transfer.destFile}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="flex items-center justify-between mt-auto">
                        <p className="text-lg font-bold">
                          {transfer.number_of_photos}
                        </p>
                        <p className="text-sm text-gray-900">Photos</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))
      )}
    </div>
  );
};

export default Stats;
