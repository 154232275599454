/* eslint-disable jsx-a11y/anchor-is-valid */
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ButtonComponent from "../elements/Button";

const CardPrice = ({
  disabled,
  onClick,
  isPopular,
  listOption,
  price,
  packName,
  link,
  description,
  additionnalMessageForDisabled,
  planType,
}) => {
  return (
    <div
      className={`relative flex flex-col mx-auto w-80 h-auto text-white rounded-2xl transition-all duration-500 ${
        isPopular
          ? "bg-gray-800 transform scale-105 border border-white"
          : "bg-gray-900"
      } p-8 ${
        disabled
          ? "opacity-50 cursor-not-allowed"
          : "hover:bg-gray-800 hover:scale-105"
      }`}
    >
      <div>
        <h3 className="text-2xl mb-2 text-center">{packName}</h3>
        <p className="text-sm mb-6 text-center h-14">{description}</p>
        <div className="flex items-center justify-center mb-6">
          <span
            className={`font-manrope text-4xl font-semibold ${
              isPopular ? "" : ""
            }`}
          >
            ${price}
          </span>
          <span className="text-xs text-gray-400 ml-2">/ mois</span>
        </div>

        <a
          href={disabled ? "#" : `${link}?planType=${planType}`}
          onClick={disabled ? (e) => e.preventDefault() : onClick}
        >
          <ButtonComponent
            optionalClassName={
              disabled
                ? `cursor-not-allowed bg-gray-400`
                : `hover:bg-gray-200 bg-white bg-gradient-to-r from-gray-100 to-gray-300`
            }
          >
            {disabled ? additionnalMessageForDisabled : "Continuer"}
          </ButtonComponent>
        </a>
      </div>
      <ul className="mt-6 space-y-4 text-left text-sm text-gray-400">
        <p>Transfert </p>
        {listOption.map((option, index) => (
          <li key={index} className="flex items-center space-x-2">
            <FontAwesomeIcon
              icon={faCheck}
              className="text-green-600"
            ></FontAwesomeIcon>
            <span>{option}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CardPrice;
