import React, { useState } from "react";
import { register } from "../../services/auth.services";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faLock,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import ButtonComponent from "../elements/Button";

const Register = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    lastname: "",
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const validatePassword = (password) => {
    const minLength = 8;
    let errorMessages = [];

    if (password.length < minLength) {
      errorMessages.push(`au moins ${minLength} caractères`);
    }

    if (!/[A-Z]/.test(password)) {
      errorMessages.push("une lettre majuscule");
    }

    if (!/\d/.test(password)) {
      errorMessages.push("un chiffre");
    }

    if (errorMessages.length > 0) {
      setErrorMessage(
        `Le mot de passe doit contenir ${errorMessages.join(", ")}.`
      );
      return false;
    }

    setErrorMessage("");
    return true;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Vérifier si les mots de passe correspondent d'abord
    if (formData.password !== formData.confirmPassword) {
      setErrorMessage("Les mots de passe ne correspondent pas.");
      setIsLoading(false);
      return;
    }

    // Valider le mot de passe ensuite
    const isPasswordValid = validatePassword(formData.password);
    if (!isPasswordValid) {
      setIsLoading(false);
      return;
    }

    setErrorMessage("");

    try {
      await register(formData);
      navigate("/login");
    } catch (error) {
      console.error(error);
      setErrorMessage("Une erreur s'est produite lors de l'inscription.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <form
        onSubmit={handleSubmit}
        className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg"
      >
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
          Inscription
        </h2>
        <div className="mb-4">
          <label htmlFor="username" className="block text-gray-600 mb-2">
            Prénom
          </label>
          <div className="flex items-center border border-gray-300 rounded-md">
            <FontAwesomeIcon icon={faUser} className="ml-3 text-gray-400" />
            <input
              type="text"
              name="username"
              placeholder="Prénom"
              onChange={handleChange}
              className="w-full p-2.5 outline-none"
              required
            />
          </div>
        </div>
        <div className="mb-4">
          <label htmlFor="lastname" className="block text-gray-600 mb-2">
            Nom
          </label>
          <div className="flex items-center border border-gray-300 rounded-md">
            <FontAwesomeIcon icon={faUser} className="ml-3 text-gray-400" />
            <input
              type="text"
              name="lastname"
              placeholder="Nom"
              onChange={handleChange}
              className="w-full p-2.5 outline-none"
              required
            />
          </div>
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-600 mb-2">
            Email
          </label>
          <div className="flex items-center border border-gray-300 rounded-md">
            <FontAwesomeIcon icon={faEnvelope} className="ml-3 text-gray-400" />
            <input
              type="email"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              className="w-full p-2.5 outline-none"
              required
            />
          </div>
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="block text-gray-600 mb-2">
            Mot de passe
          </label>
          <div className="flex items-center border border-gray-300 rounded-md">
            <FontAwesomeIcon icon={faLock} className="ml-3 text-gray-400" />
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Mot de passe"
              onChange={handleChange}
              className="w-full p-2.5 outline-none"
              required
            />
            <button
              type="button"
              className="ml-3 p-2"
              onClick={() => setShowPassword(!showPassword)}
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </button>
          </div>
        </div>

        <div className="mb-4">
          <label htmlFor="confirmPassword" className="block text-gray-600 mb-2">
            Confirmer le mot de passe
          </label>
          <div className="flex items-center border border-gray-300 rounded-md">
            <FontAwesomeIcon icon={faLock} className="ml-3 text-gray-400" />
            <input
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              placeholder="Confirmer le mot de passe"
              onChange={handleChange}
              className="w-full p-2.5 outline-none"
              required
            />
            <button
              type="button"
              className="ml-3 p-2"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              <FontAwesomeIcon
                icon={showConfirmPassword ? faEyeSlash : faEye}
              />
            </button>
          </div>
        </div>

        {errorMessage && (
          <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
        )}

        <ButtonComponent
          type="submit"
          className="w-full py-2 px-4 bg-black text-white rounded-md"
          disabled={isLoading}
        >
          {isLoading ? "Inscription en cours..." : "Inscription"}
        </ButtonComponent>
        <p className="mt-4 text-center text-gray-600">
          Vous avez déjà un compte ?{" "}
          <Link to="/login" className="text-black hover:underline">
            Connectez-vous ici
          </Link>
        </p>
      </form>
    </div>
  );
};

export default Register;
