import React, { useState } from "react";

import FormLogin from "./FormLogin";

const Login = () => {
  return (
    <div className="flex items-center justify-center  h-screen">
      <FormLogin goTo={"/"}></FormLogin>
    </div>
  );
};

export default Login;
