import React from "react";

const ButtonComponent = ({
  children,
  onClick,
  optionalClassName = "", // Définit une valeur par défaut vide si optionalClassName est undefined
  type,
  color,
}) => {
  const colorClasses = {
    warning: "bg-gradient-to-r from-orange-700 to-orange-800 text-white",
    success: "bg-gradient-to-r from-green-700 to-green-800 text-white",
    normal: "bg-gradient-to-r from-gray-700 to-gray-800 text-white",
    danger: "bg-gradient-to-r from-red-700 to-red-800 text-white",
    white : "bg-gradient-to-r from-gray-100 to-gray-300 text-black"
  };

  const defaultBackgroundClass = colorClasses[color] || colorClasses["normal"];

  return (
    <button
      type={type || "button"}
      className={`px-6 py-3 z-10 w-full  rounded cursor-pointer shadow-lg text-black ${
        optionalClassName.includes("bg-")
          ? optionalClassName
          : defaultBackgroundClass
      } ${optionalClassName}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default ButtonComponent;
