import React from "react";
import no from "../../images/unsubscribe/no.gif";
import ButtonComponent from "../elements/Button";
import moment from "moment";
import { cancelSubscription } from "../../services/stripe.services";

const ModalUnsubscribe = ({ isOpen, onClose, userData,  userId }) => {
  if (!isOpen) return null;

  const handleCancelSubscription = async () => {
    try {
      const response = await cancelSubscription(userId);
      if (response && response.status === 200) {
        console.log("Subscription canceled successfully:", response.data);
        onClose(); // Fermer la fenêtre ou effectuer une autre action après l'annulation
      } else {
        console.error(
          "Failed to cancel subscription:",
          response?.data?.message
        );
      }
    } catch (error) {
      console.error("Error canceling subscription:", error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-semibold mb-6 text-center">
          On est triste de vous voir partir
        </h2>
        <img
          src={no}
          alt="no"
          className="w-full h-72 object-cover mb-6 rounded-lg"
        />
        <p className="text-center text-lg mb-6">
          Êtes-vous sûr de vouloir vous désabonner ?
        </p>
        <p className="text-center text-lg mb-6">
          les options de votre abonnement ce terminerons le{" "}
          <span className="font-bold">
            {moment(userData.subscription.endDate).format("LL")}
          </span>
        </p>
        <div className="flex justify-center space-x-4">
          <ButtonComponent
            className="px-6 py-3 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition"
            onClick={onClose}
          >
            Annuler
          </ButtonComponent>
          <ButtonComponent
            onClick={handleCancelSubscription}
            className="px-6 py-3 bg-red-500 text-white rounded-lg hover:bg-red-600 transition"
          >
            Confirmer
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};

export default ModalUnsubscribe;
