import React from "react";
import ButtonComponent from "../../elements/Button";

const InvoiceCard = ({ invoice }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-6 max-w-md mx-auto">
      <div className="text-center mb-4">
        <div className="flex justify-center items-center mb-2">
          <img
            src="https://img.icons8.com/ios-filled/50/000000/invoice.png"
            alt="Invoice Icon"
            className="w-12 h-12"
          />
        </div>
        <h3 className="text-xl font-semibold text-gray-700">Facture payée</h3>
        <p className="text-4xl font-bold text-gray-900 my-2">
          {(invoice.amount_paid / 100).toLocaleString("fr-FR", {
            style: "currency",
            currency: invoice.currency.toUpperCase(),
          })}
        </p>
        <a
          href={invoice.hosted_invoice_url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:underline"
        >
          Afficher les détails de la facture &gt;
        </a>
      </div>
      <div className="text-left text-gray-600 mb-4">
        <p className="mb-2">
          <span className="font-medium">Numéro de facture :</span>{" "}
          {invoice.number}
        </p>
        <p className="mb-2">
          <span className="font-medium">Date du paiement :</span>{" "}
          {new Date(invoice.created * 1000).toLocaleDateString("fr-FR")}
        </p>
      </div>
      <div className=" mt-4 w-full flex items-center justify-center">
        <a href={invoice.invoice_pdf} target="_blank" rel="noopener noreferrer">
          <ButtonComponent>Télécharger la facture</ButtonComponent>
        </a>
      </div>
    </div>
  );
};

export default InvoiceCard;
