import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  ForgotPassword,
  VerifyResetCode,
  ResetPassword,
} from "../../services/auth.services";

const ResetPasswordComponent = () => {
  const [formData, setFormData] = useState({ email: "" });
  const [codeReset, setCodeReset] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isVerified, setIsVerified] = useState(false);


  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await ForgotPassword(formData);


      Swal.fire({
        title: "Code de vérification",
        input: "text",
        inputLabel: "Entrez le code de vérification envoyé à votre email",
        showCancelButton: true,
        confirmButtonText: "Vérifier",
        showLoaderOnConfirm: true,
        preConfirm: async (codeReset) => {
          try {
            const response = await VerifyResetCode(formData.email, codeReset);
            if (
              response.data.message === "Invalid code" ||
              response.data.message === "Code expired"
            ) {
              throw new Error(response.data.message);
            }
            setCodeReset(codeReset);
            setIsVerified(true);
            Swal.fire({
              icon: "success",
              title: "Code vérifié avec succès !",
              showConfirmButton: false,
              timer: 1500,
            });
          } catch (error) {
            Swal.showValidationMessage(
              "Code invalide ou expiré. Veuillez réessayer."
            );
          }
        },
      });
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Échec de l'envoi du code de réinitialisation !",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const handleResetPassword = async () => {
    try {
      await ResetPassword(formData.email, codeReset, newPassword);

      Swal.fire({
        icon: "success",
        title: "Mot de passe réinitialisé avec succès !",
        showConfirmButton: false,
        timer: 1500,
      });

      navigate("/login");
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Échec de la réinitialisation du mot de passe !",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen ">
      <form
        onSubmit={handleSubmit}
        className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg"
      >
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
          Réinitialisation du mot de passe
        </h2>
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-600 mb-2">
            Email
          </label>
          <div className="flex items-center border border-gray-300 rounded-md">
            <FontAwesomeIcon icon={faEnvelope} className="ml-3 text-gray-400" />
            <input
              type="email"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              className="w-full p-2.5 outline-none"
              required
            />
          </div>
        </div>

        {isVerified && (
          <div className="mb-4">
            <label htmlFor="newPassword" className="block text-gray-600 mb-2">
              Nouveau mot de passe
            </label>
            <input
              type="password"
              name="newPassword"
              placeholder="Nouveau mot de passe"
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full p-2.5 border border-gray-300 rounded-md outline-none"
              required
            />
            <button
              type="button"
              onClick={handleResetPassword}
              className="w-full py-2 px-4 mt-4 bg-black text-white rounded-md"
            >
              Réinitialiser le mot de passe
            </button>
          </div>
        )}

        {!isVerified && (
          <button
            type="submit"
            className="w-full py-2 px-4 bg-black text-white rounded-md"
          >
            Envoyer le code de réinitialisation
          </button>
        )}

        <p className="mt-4 text-center text-gray-600">
          Pas de compte ?{" "}
          <a href="/register" className="text-black hover:underline">
            Inscrivez-vous ici
          </a>
        </p>
        <p className="mt-4 text-center text-gray-600">
          Déjà un compte ?{" "}
          <a href="/login" className="text-black hover:underline">
            Connectez-vous ici
          </a>
        </p>
      </form>
    </div>
  );
};

export default ResetPasswordComponent;
