import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "../../elements/Button";

const SubscriptionCard = ({ price, option, planType }) => {
  return (
    <div className="flex flex-col items-center w-full  rounded-xl  text-black">
      <div className="flex mb-4">
        <span className="text-xl font-bold uppercase tracking-wider">
          Abonnement {planType?.charAt(0).toUpperCase() + planType.slice(1)}
        </span>
      </div>
      <div className="flex flex-col items-center mt-4">
        <span className="text-4xl font-bold">{price}€ / mois</span>
        <span className="text-sm font-light mt-2">Abonnement mensuel</span>
      </div>
      <div className="flex w-full mt-6">
        <ul className="space-y-4 text-left text-lg">
          {option.map((option, index) => (
            <li key={index} className="flex items-center space-x-3">
              <FontAwesomeIcon icon={faCheck} className="text-green-300" />
              <span>{option}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SubscriptionCard;
