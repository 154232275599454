import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
// const API_URL = process.env.REACT_APP_API_URL_LOCAL;

export const register = (user) => {
  const req = axios.post(`${API_URL}/auth/register`, user);
  if (!req) return;
  return req;
};

export const login = (user) => {
  const req = axios.post(`${API_URL}/auth/login`, user);
  if (!req) return;
  return req;
};

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export const ForgotPassword = (email) => {
  const req = axios.post(`${API_URL}/auth/forgot-password`, email);
  if (!req) return;
  return req;
};

export const VerifyResetCode = (email, codeReset) => {
  const req = axios.post(`${API_URL}/auth/verify-reset-code`, {
    email,
    codeReset,
  });
  if (!req) return;
  return req;
};

export const ResetPassword = (email, codeReset, newPassword) => {
  const req = axios.post(`${API_URL}/auth/reset-password`, {
    email,
    codeReset,
    newPassword,
  });
  if (!req) return;
  return req;
};

export const getToken = () => {
  return localStorage.getItem("token");
};
