import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Button from "./elements/Button";
import Price from "./Price/Price";
import Product from "./Product/Product";
import { HomePageContact } from "./contact/HomePageContact";
import { useAuth } from "./auth/AuthProvider";

function HomePage() {
  const { user, userData } = useAuth();
  const navigate = useNavigate();

  const handleGoToFilter = () => {
    if (user) {
      navigate("/organize");
    } else {
      navigate("/login", { state: { from: "/organize" } });
    }
  };

  return (
    <>
      <section
        id="home"
        className="relative h-screen flex justify-center items-center flex-col gap-8 bg-gradient-to-r from-gray-900 to-gray-700 px-4 sm:px-6 lg:px-8"
      >
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="z-10 text-center"
        >
          <h1 className="bg-gradient-to-br from-white to-gray-300 bg-clip-text text-transparent py-6 text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold leading-none tracking-tight">
            Organiser vos photos n'a jamais été aussi simple
          </h1>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: -40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="z-10 text-center"
        >
          <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl text-gray-400">
            Organisez vos photos par ISO, Dates, Formats, Localisation et bien
            plus encore
          </h2>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.6 }}
          className="z-10"
        >
          <Button color={"white"} onClick={handleGoToFilter}>
            Commencer
          </Button>
        </motion.div>
      </section>

      <section id="product" className="px-4 sm:px-6 lg:px-8 py-8">
        <Product />
      </section>
      <section id="price" className="px-4 sm:px-6 lg:px-8 py-8">
        <Price userData={userData} />
      </section>
    </>
  );
}

export default HomePage;
