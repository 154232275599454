import React, { useState, useEffect } from "react";

const CookieConsent = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const consentGiven = localStorage.getItem("cookieConsent");
    if (!consentGiven) {
      setVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true");
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <div className="fixed bottom-0 left-0 w-full bg-gray-800 text-white p-4 flex justify-between items-center">
      <div className="text-sm">
        Ce site utilise des cookies pour améliorer votre expérience et analyser
        le trafic. En continuant à utiliser ce site, vous acceptez notre{" "}
        <a href="/cookies-policy" className="underline">
          Politique de cookies
        </a>
        .
      </div>
      <button
        onClick={handleAccept}
        className="ml-4 px-4 py-2 bg-blue-500 text-white rounded"
      >
        J'ai compris
      </button>
    </div>
  );
};

export default CookieConsent;
