import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import folder from "../../images/folder.png";

function DirectoryInput() {
  const [items, setItems] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [modalImage, setModalImage] = useState(null);
  const location = useLocation();
  const targetDir = location.state?.targetDir;
  const navigate = useNavigate();
  useEffect(() => {
    if (targetDir) {
      handleDirectoryChange(targetDir);
    }
  }, [targetDir]);

  // useEffect(() => {
  //   if (items.length === 0) {
  //     navigate("/");
  //   }
  // }, [items, navigate]);

  const handleDirectoryChange = async (directoryHandle) => {
    const items = [];
    const checked = {};

    for await (const entry of directoryHandle.values()) {
      if (entry.kind === "file") {
        const file = await entry.getFile();
        items.push({
          name: file.name,
          url: URL.createObjectURL(file),
          type: file.type,
          file: file,
        });
        checked[file.name] = false;
      } else if (entry.kind === "directory") {
        items.push({
          name: entry.name,
          type: "directory",
          items: await readDirectory(entry),
        });
      }
    }

    setItems(items);
    setCheckedItems(checked);
  };

  const readDirectory = async (directoryHandle) => {
    const items = [];
    for await (const entry of directoryHandle.values()) {
      if (entry.kind === "file") {
        const file = await entry.getFile();
        items.push({
          name: file.name,
          url: URL.createObjectURL(file),
          type: file.type,
          file: file,
        });
      } else if (entry.kind === "directory") {
        items.push({
          name: entry.name,
          type: "directory",
          items: await readDirectory(entry),
        });
      }
    }
    return items;
  };

  const handleFileSelection = (file) => {
    const filePath = file.name;
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [filePath]: !prevCheckedItems[filePath],
    }));

    setSelectedFiles((prevSelectedFiles) =>
      prevSelectedFiles.includes(file)
        ? prevSelectedFiles.filter((f) => f !== file)
        : [...prevSelectedFiles, file]
    );
  };

  const downloadSelectedFiles = () => {
    selectedFiles.forEach(async (file) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(file.file);
      link.download = file.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const handleReset = () => {
    setSelectedFiles([]);
    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = {};
      for (const key in prevCheckedItems) {
        newCheckedItems[key] = false;
      }
      return newCheckedItems;
    });
  };

  const openModal = (image) => {
    setModalImage(image);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  const renderItems = (items) => (
    <div className="flex flex-wrap gap-5">
      {items.map((item, index) =>
        item.type === "directory" ? (
          <div key={index} className="w-full mb-4">
            <div className="flex items-center mb-2">
              <img src={folder} alt="folder" className="w-5 h-5 mr-2" />
              <strong className="font-semibold text-lg">{item.name}</strong>
            </div>
            <div className="ml-4">{renderItems(item.items)}</div>
          </div>
        ) : item.type.startsWith("image/") ? (
          <div
            key={index}
            className={`relative mb-4 transition-transform transform ${
              checkedItems[item.name] ? "scale-95" : ""
            }`}
            style={{ width: "200px" }}
          >
            <label className="absolute top-0 right-0 m-2 cursor-pointer">
              <input
                type="checkbox"
                checked={checkedItems[item.name] || false}
                onChange={() => handleFileSelection(item)}
                className="hidden"
              />
              <span
                className={`block w-8 h-8 rounded-full ${
                  checkedItems[item.name]
                    ? "bg-green-500"
                    : "bg-white border-2 border-gray-300"
                } flex items-center justify-center`}
              >
                {checkedItems[item.name] && (
                  <svg
                    className="w-4 h-4 text-white"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                )}
              </span>
            </label>
            <img
              src={item.url}
              alt={item.name}
              className={`w-full h-auto object-cover rounded shadow-lg cursor-pointer`}
              onClick={() => openModal(item.url)}
            />
          </div>
        ) : (
          <div key={index} className="flex items-center space-x-2 mb-4">
            <input
              type="checkbox"
              checked={checkedItems[item.name] || false}
              onChange={() => handleFileSelection(item)}
              className="form-checkbox"
            />
            <span>{item.name}</span>
          </div>
        )
      )}
    </div>
  );

  return (
    <div className="min-h-screen p-4">
      <header className="flex justify-between items-center p-6">
        <div className="flex items-center space-x-4">
          <a href="/" className="text-2xl font-bold">
            Photo Organizer
          </a>
        </div>
      </header>
      <main className="p-8">
        <h3 className="text-xl font-semibold mb-4">Images du dossier :</h3>
        {renderItems(items)}
        <div className="fixed bottom-4 left-0 right-0 flex justify-center space-x-4">
          {selectedFiles.length > 0 && (
            <>
              <button
                onClick={downloadSelectedFiles}
                className="px-6 py-3 text-xl bg-black text-white rounded hover:bg-gray-800 mb-4 mt-8"
              >
                Télécharger les fichiers sélectionnés
              </button>
              <button
                onClick={handleReset}
                className="px-6 py-3 text-xl bg-black text-white rounded hover:bg-gray-800 mb-4 mt-8"
              >
                Réinitialiser la sélection
              </button>
            </>
          )}
        </div>
        {modalImage && (
          <div
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center"
            onClick={closeModal}
          >
            <img
              src={modalImage}
              alt="Modal"
              className="max-w-full max-h-full"
            />
          </div>
        )}
      </main>
    </div>
  );
}

export default DirectoryInput;
