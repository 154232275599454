import React, { useEffect } from "react";
import { useAuth } from "../../auth/AuthProvider";
import { useNavigate } from "react-router-dom";

const Success = () => {
  const { userId } = useAuth();
  const navigate = useNavigate();

  const handlePaymentSuccess = async () => {
    navigate("/");
  };

  useEffect(() => {
    if (!userId) {
      navigate("/login");
    }
  }, [userId, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center p-4">
      <div className="bg-white p-6 sm:p-10 rounded-lg shadow-lg text-center max-w-lg w-full">
        <h1 className="text-3xl sm:text-4xl font-bold text-green-600 mb-4">
          Succès !
        </h1>
        <p className="text-base sm:text-lg text-gray-700 mb-8">
          Votre transaction a été réalisée avec succès.
        </p>
        <div className="flex justify-center">
          <button
            onClick={handlePaymentSuccess}
            className="w-full max-w-xs uppercase bg-[#009C96] text-white text-lg sm:text-xl px-4 py-2 rounded-lg hover:bg-[#007C7A] transition-all duration-200"
          >
            Continuer
          </button>
        </div>
      </div>
    </div>
  );
};

export default Success;
