import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DirectoryInput from "./components/Organizer/DirectoryInput";
import "./App.css";
import HomePage from "./components/HomePage";
import { Analytics } from "@vercel/analytics/react";
import TutorialVideo from "./components/TutorialVideo";
import Navbar from "./components/Navbar/NavBar";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import ResetPasswordComponent from "./components/auth/ResetPassword";
import Profil from "./components/Profil/Profil";
import Product from "./components/Product/Product";
import HomeOrganizer from "./components/Organizer/HomeOrganizer";
import HomeStandard from "./components/Price/Standard/HomeStandard";
import { AuthProvider, useAuth } from "./components/auth/AuthProvider";
import Error404 from "./components/elements/Error404";
import Footer from "./components/footer/Footer";
import HomePageContact from "./components/contact/HomePageContact";
import BackOfficeFaq from "./components/contact/FAQ/BackOffice";
import FormContact from "./components/contact/FormContact";
import PrivacyPolicy from "./components/footer/legal/PrivacyPolicy";
import TermsAndConditions from "./components/footer/legal/TermsAndConditions";
import Cookies from "./components/footer/legal/Cookies";
import Stats from "./components/Profil/Stats/Stats";
import AdminRoute from "./components/auth/AdminRoute";
import BackOffice from "./components/contact/FAQ/BackOffice";
import CookieConsent from "./components/auth/CookieConsent";
import Success from "./components/Price/Standard/Success";
import Cancel from "./components/Price/Standard/Cancel";

const App = () => {
  return (
    <div className="bg-gradient-to-r from-gray-900 to-gray-700 min-h-screen flex flex-col">
      <Router>
        <AuthProvider>
          <Navbar />
          <div className="flex-grow">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/product" element={<Product />} />
              <Route path="/profil" element={<Profil />} />
              <Route path="/standard" element={<HomeStandard />} />
              <Route path="/contact" element={<HomePageContact />} />
              <Route path="/history-stats" element={<Stats />} />
              <Route path="/success" element={<Success />} />
              <Route path="/cancel" element={<Cancel />} />

              <Route
                path="/forgot-password"
                element={<ResetPasswordComponent />}
              />
              <Route path="/organize" element={<HomeOrganizer />} />

              <Route element={<AdminRoute redirectTo="/" />}>
                <Route path="/backoffice" element={<BackOffice />} />
              </Route>
              <Route path="/contact/new" element={<FormContact />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions />}
              />
              <Route path="/cookies-policy" element={<Cookies />} />

              <Route path="*" element={<Error404 />} />
            </Routes>
          </div>
          <Footer />
          <CookieConsent />
          <Analytics />
        </AuthProvider>
      </Router>
    </div>
  );
};

export default App;
