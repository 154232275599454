import React from "react";

const Error404 = () => {
  return (
    <div className="flex justify-center items-center text-9xl text-white font-bold h-screen">
      Error404
    </div>
  );
};

export default Error404;
