import React from "react";
import { ReactComponent as FolderIconSvg } from "./Icons8_flat_folder.svg"; // Assurez-vous que le chemin est correct
import ButtonComponent from "../elements/Button";

const FolderIcon = ({ text, onClick, subText, folderName }) => {
  return (
    <div className="flex items-center justify-center cursor-pointer text-white   ">
      <div className="relative inline-block" onClick={onClick}>
        <FolderIconSvg className="w-96 h-96" />
        <div className="absolute inset-0 flex mt-28 flex-col ml-12 mr-12 text-lg text-black font-bold ">
          <div className="text-white h-14 flex">{text}</div>
          <div className="text-sm font-normal text-white">
            {subText}
          </div>
          <div className="w-full flex items-center justify-center h-32 ">
            {folderName ? (
              <span className="text-lg  font-bold text-white">
                {folderName}
              </span>
            ) : (
              <ButtonComponent color={'white'} >Sélectionner un fichier</ButtonComponent>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FolderIcon;
