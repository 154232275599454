import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Button from "../elements/Button";
import { addTransferToUser, checkPlan } from "../../services/transfer.services";
import FolderIcon from "./FolderIcon";
import useFileOrganizer from "../../hooks/useFileOrganizer";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import ModalSelectFilters from "../Modals/ModalSelectFilters";
import ModalTransfert from "../Modals/ModalTransfert";
import { useAuth } from "../auth/AuthProvider";

const HomeOrganizer = () => {
  const { user } = useAuth();
  const [sourceDir, setSourceDir] = useState(null);
  const [targetDir, setTargetDir] = useState(null);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }

    // Vérifiez si l'utilisateur est sur un appareil mobile
    if (isMobile) {
      Swal.fire({
        icon: "warning",
        title: "Utilisation mobile non supportée",
        text: "Cette fonctionnalité n'est pas optimisée pour les appareils mobiles. Vous allez être redirigé vers l'accueil.",
        confirmButtonText: "OK",
      }).then(() => {
        navigate("/"); // Redirige vers la page d'accueil
      });
    }
  }, [user, navigate]);

  const {
    selectDirectory,
    getNumberFilesInSourceDir,
    organizeFilesByOption,
    removeSourceFiles,
    getFilesFromDirectory,
    checkFormatFiles,
  } = useFileOrganizer();

  const handleConfirmFilters = async (filters) => {
    setIsLoading(true); // Commence le chargement
    setProgress(0); // Réinitialise la progression
    setIsFilterModalOpen(false); // Ferme la fenêtre modale

    try {
      let groupBy = null;
      if (filters.sortOption === "location") {
        const { value: groupByOption } = await Swal.fire({
          title: "Organiser par",
          input: "radio",
          inputOptions: {
            city: "Ville",
            country: "Pays",
          },
          allowOutsideClick: false,
          allowEscapeKey: false,

          inputValidator: (value) => {
            if (!value) {
              return "Vous devez choisir une option !";
            }
          },
        });
        groupBy = groupByOption;
      }

      await organizeFilesByOption(
        files,
        filters.sortOption,
        targetDir,
        setProgress,
        groupBy
      );

      if (filters.deleteSource) {
        await removeSourceFiles(files, sourceDir);
      }

      Swal.fire({
        icon: "success",
        title: "Succès",
        text: "Photos organisées avec succès.",
      }).then(() => {
        addTransferToUser({
          userId: user.userId,
          number_of_photos: files.length,
          filter: filters.sortOption,
          destFile: targetDir.name,
          sourceFile: sourceDir.name,
          allowMail: filters.allowMail,
        });
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: "Une erreur est survenue lors de l'organisation des photos.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const openSortOptions = async () => {
    if (!sourceDir || !targetDir) {
      Swal.fire({
        icon: "warning",
        title: "Attention",
        text: "Veuillez sélectionner les deux dossiers.",
      });
      return;
    }

    const number_of_photos = await getNumberFilesInSourceDir(sourceDir);

    const formatIsValid = await checkFormatFiles(sourceDir);

    if (!formatIsValid) {
      return;
    }

    const result = await checkPlan(user.userId, number_of_photos);

    if (result.status === "limit_exceeded") {
      let alertText = result.message;
      if (result.reason === "transfers") {
        alertText += ` Vous avez effectué ${result.totalTransfersToday} transferts sur ${result.maxTransfersPerDay} autorisés.`;
      } else if (result.reason === "photos") {
        alertText += ` Vous avez demandé ${result.number_of_photos} photos, la limite est de ${result.maxPhotosPerTransfer} photos par transfert.`;
      }
      Swal.fire({
        icon: "error",
        title: "Limite dépassée",
        text: alertText,
        showCancelButton: true,
        confirmButtonText: "Mettre à niveau",
        cancelButtonText: "Annuler",
      }).then((result) => {
        if (result.isConfirmed) {
          let planType = "";
          const _planType = user.subscription.planType;
          if (_planType === "free") {
            planType = "standard";
          }
          if (_planType === "standard") {
            planType = "premium";
          }
          navigate("/standard?planType=" + planType);
        }
      });
    } else {
      const newFiles = await getFilesFromDirectory(sourceDir);
      setFiles(newFiles);
      setIsFilterModalOpen(true);
    }
  };

  return (
    <section className="flex justify-center items-center flex-col h-screen text-white">
      <div className="flex justify-center items-center flex-col gap-5">
        <h1 className="text-6xl font-bold">Organisez vos photos</h1>
        <h1>
          Sélectionnez les répertoires source et cible pour organiser vos
          photos.
        </h1>
      </div>
      <div className="flex flex-row">
        <FolderIcon
          text={"Sélectionnez un dossier source"}
          subText={
            "Le dossier source est le dossier où se trouvent les photos à organiser."
          }
          folderName={sourceDir ? sourceDir.name : ""}
          onClick={() => selectDirectory(setSourceDir)}
        />
        <FolderIcon
          text={"Sélectionnez un dossier de destination"}
          subText={
            "Le dossier de destination est le dossier où les photos seront organisées par format et dates."
          }
          folderName={targetDir ? targetDir.name : ""}
          onClick={() => selectDirectory(setTargetDir)}
        />
      </div>
      <div>
        <Button onClick={openSortOptions} optionalClassName={"bg-white"}>
          Organiser les photos
        </Button>
        {isLoading && (
          <ModalTransfert isLoading={isLoading} progress={progress} />
        )}
        <ModalSelectFilters
          plan={user?.subscription.planType}
          isOpen={isFilterModalOpen}
          onClose={() => setIsFilterModalOpen(false)}
          onConfirm={handleConfirmFilters}
        />
      </div>
    </section>
  );
};

export default HomeOrganizer;
