import { getUserById } from "../../services/user.services";
import { setAuthToken } from "../../services/auth.services";
import React, { useState, useEffect, createContext, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const navigate = useNavigate();
  const location = useLocation();

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (token) {
      setAuthToken(token);
    }
  }, [token]);

  const decodeToken = (token) => {
    const payload = token.split(".")[1];
    const decoded = atob(payload);
    return JSON.parse(decoded);
  };

  const handleLogin = (token, from) => {
    setToken(token);
    localStorage.setItem("token", token);
    setAuthToken(token);

    const redirectTo = from;
    navigate(redirectTo);
  };

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem("token");
    setAuthToken(null);
    navigate("/");

    window.location.reload();
  };
  useEffect(() => {

    if (!token) return;
    const fetchUser = async () => {
      const _user = await getUserById(user.userId);
      if (!_user) return;
      setUserData(_user);
    };
    fetchUser();
  }, [token]);

  const isAdmin = token ? decodeToken(token).role === "admin" : false;
  const userId = token ? decodeToken(token).userId : null;
  const user = token ? decodeToken(token) : null;

  return (
    <AuthContext.Provider
      value={{
        token,
        user,
        userData,
        userId,
        isAdmin,
        handleLogin,
        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
