import React, { useState } from "react";
import Slider from "react-slick";
import { motion } from "framer-motion";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import icon1 from "./icons/icon1.png";
import icon2 from "./icons/icon2.png";
import icon3 from "./icons/icon3.png";
import icon4 from "./icons/icon4.png";
import icon5 from "./icons/icon5.png";
import icon6 from "./icons/icon6.png";
import icon7 from "./icons/icon7.png";
import icon8 from "./icons/icon8.png";
import icon9 from "./icons/icon9.png";
import icon10 from "./icons/icon10.png";

import img1 from "../../images/product/1.png";
import img2 from "../../images/product/2.png";
import img3 from "../../images/product/3.png";
import img4 from "../../images/product/4.png";
import img5 from "../../images/product/5.png";
import img6 from "../../images/product/6.png";
import img7 from "../../images/product/7.png";
import img8 from "../../images/product/8.png";
import img9 from "../../images/product/9.png";
import img10 from "../../images/product/1.png";

const features = [
  {
    title: "Transfert simple",
    description: "Les photos seront transférées sans tri.",
    icon: icon1,
    image: img1,
  },
  {
    title: "Trier par format",
    description:
      "Les photos seront triées par format, des dossiers seront créés pour chaque format.",
    icon: icon2,
    image: img2,
  },
  {
    title: "Transfert par date",
    description: "Transfert de vos photos par date de prise de vue.",
    icon: icon3,
    image: img3,
  },
  {
    title: "Trier par format puis par date",
    icon: icon4,
    description:
      "Les photos seront triées par format, puis par date. Un dossier sera créé pour chaque format, et des sous-dossiers pour chaque date.",
    image: img4,
  },
  {
    title: "Trier par date puis par format",
    icon: icon5,
    description:
      "Les photos seront triées par date, puis par format. Un dossier sera créé pour chaque date, et des sous-dossiers pour chaque format.",
    image: img5,
  },
  {
    title: "Trier par localisation",
    icon: icon6,
    description: "Les photos seront triées par localisation.",
    image: img6,
  },
  {
    title: "Trier par marque et modèle",
    icon: icon7,
    description: "Les photos seront triées par l'appareil qui a pris la photo.",
    image: img7,
  },
  {
    title: "Trier par distance focale",
    icon: icon8,
    description: "Les photos seront triées par distance focale.",
    image: img8,
  },
  {
    title: "Trier par ISO",
    icon: icon9,
    description:
      "Les photos seront triées par International Organization for Standardization (ISO).",
    image: img9,
  },
  // {
  //   title: "Trier par IA",
  //   icon: icon10,
  //   description: "Les photos seront triées par IA.",
  //   image: img10,
  // },
];

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none" }}
      onClick={onClick}
    />
  );
};

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none" }}
      onClick={onClick}
    />
  );
};

const ProductFeatures = () => {
  const [selectedFeature, setSelectedFeature] = useState(features[0]);

  const settings = {
    centerMode: true,
    centerPadding: "0px",
    slidesToShow: 3,
    speed: 500,
    focusOnSelect: true,
    autoplay: true, // Corrected property name
    autoplaySpeed: 3000,
    afterChange: (current) => setSelectedFeature(features[current]),
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div className="text-white w-full px-4 sm:px-8">
      <div className="text-center mb-8 sm:mb-12">
        <h2 className="text-3xl sm:text-5xl font-bold">
          Organisateur de photos en ligne. Léger mais puissant.
        </h2>
        <p className="mt-4 text-base sm:text-lg">
          Le meilleur organisateur en ligne qui vous fera gagner un temps
          précieux.
        </p>
      </div>
      <div className="w-full max-w-8xl mx-auto mb-8">
        <Slider {...settings}>
          {features.map((feature, index) => (
            <div
              key={index}
              className={`px-2 ${
                selectedFeature.title === feature.title
                  ? "scale-105 opacity-100"
                  : "opacity-50"
              } transition-transform duration-300`}
            >
              <motion.button className="text-center w-full flex flex-col items-center justify-center p-4 rounded-lg transition-all duration-300">
                <div>
                  <img
                    src={feature.icon}
                    alt={feature.title}
                    className="w-12 h-12 sm:w-16 sm:h-16"
                  />
                </div>
                <h3 className="text-base sm:text-lg font-semibold">
                  {feature.title}
                </h3>
                <motion.div
                  className="mt-2"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{
                    opacity: selectedFeature.title === feature.title ? 1 : 0,
                    y: selectedFeature.title === feature.title ? 0 : 20,
                  }}
                  transition={{ duration: 0.3 }}
                >
                  <p className="text-xs sm:text-sm">{feature.description}</p>
                </motion.div>
              </motion.button>
            </div>
          ))}
        </Slider>
      </div>

      <motion.div
        className="w-full max-w-6xl mx-auto hidden sm:block "
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5 }}
      >
        <img
          src={selectedFeature.image}
          alt={selectedFeature.title}
          className="w-full h-auto rounded-lg shadow-lg border border-white"
        />
      </motion.div>
    </div>
  );
};

export default ProductFeatures;
