import React from "react";
import CardPrice from "../Price/CardPrice";

const Price = ({ userData }) => {
  const isStandard = userData?.subscription.planType === "standard";
  const isPremium = userData?.subscription.planType === "premium";

  return (
    <div id="#price" className="  flex  flex-col justify-center items-center  ">
      <div className="text-center flex flex-col gap-5 text-white mb-12">
        <h2 className="text-8xl mb-8 mt-8">Tarifs</h2>
      </div>

      <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-8 lg:space-y-0 lg:items-stretch">
        <CardPrice
          listOption={[
            "Transfert simple",
            "Transfert par format",
            "Transfert par date",
            "3 transferts par jour",
            "50 photos par transfert",
          ]}
          price={0}
          packName="Gratuit"
          link="/organize"
          description="Idéal pour les particuliers et les startups souhaitant commencer avec des fonctionnalités essentielles."
        />
        <CardPrice
          disabled={isStandard}
          additionnalMessageForDisabled="Vous possédez déjà l'abonnement Standard"
          isPopular={true}
          listOption={[
            "Toutes les fonctionnalités du plan gratuit",
            "Transfert par date puis format",
            "Transfert par format puis date",
            "Transfert par localisation",
            "Transfert par marque et modèle de l'appareil",
            "Transfert par ISO",
            "Transfert par distance focale",
            "10 transferts par jour",
            "500 photos par transfert",
          ]}
          price={5}
          packName="Standard"
          link="/standard"
          planType="standard"
          description="Parfait pour les particuliers et les entreprises qui recherchent des options de transfert plus avancées et des limites accrues."
        />
        <CardPrice
          disabled={isPremium}
          additionnalMessageForDisabled="Vous possédez déjà l'abonnement Premium"
          listOption={[
            "Toutes les fonctionnalités",
            // "Transfert par IA",
            "100 transferts par jour",
            "15 000 photos par transfert",
          ]}
          price={10}
          packName="Premium"
          planType="premium"
          link="/standard"
          description="Conçu pour les utilisateurs exigeants qui nécessitent des fonctionnalités avancées et des capacités de transfert supérieures."
        />
      </div>
    </div>
  );
};

export default Price;
