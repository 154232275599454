import React, { useEffect, useState } from "react";
import SubscriptionCard from "../SubscriptionCard/SubscriptionCard";
import Button from "../../elements/Button";
import FormLogin from "../../auth/FormLogin";
import StripeWrapper from "../StripeBuyButton/StripeBuyButton";
import { useAuth } from "../../auth/AuthProvider";
import {
  createSession,
  updateSubscription,
} from "../../../services/stripe.services";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const HomeStandard = () => {
  const [isLogin, setIsLogin] = useState(false);
  const { userData, userId } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const planType = queryParams.get("planType");

  useEffect(() => {
    if (userId) {
      setIsLogin(true);
    }
  }, [userId]);

  const handleLoginSuccess = () => {
    setIsLogin(true);
  };

  const handleCheckOutSession = async () => {
    const session = await createSession(planType, userId);
    if (session) {
      window.location = session.data.url;
    }
  };

  const handleUpdatePlan = async () => {
    const confirmation = await Swal.fire({
      icon: "warning",
      title: "Confirmer la mise à jour",
      text: `Êtes-vous sûr de vouloir mettre à jour votre abonnement vers le plan ${planType} ? Vous serez facturé en conséquence.`,
      showCancelButton: true,
      confirmButtonText: "Oui, mettre à jour",
      cancelButtonText: "Annuler",
    });

    if (confirmation.isConfirmed) {
      try {
        const update = await updateSubscription(userId, planType);
        if (update) {
          Swal.fire({
            icon: "success",
            title: "Abonnement mis à jour",
            text: `Votre abonnement a été mis à jour vers le plan ${planType}. Vous pouvez consulter vos factures dans la section "Factures".`,
            confirmButtonText: "Voir les factures",
            showCancelButton: true,
            cancelButtonText: "Fermer",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/profil");
            }
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erreur",
          text: "Une erreur est survenue lors de la mise à jour de votre abonnement. Veuillez réessayer.",
        });
      }
    }
  };

  const planPrice =
    planType === "standard" ? 5 : planType === "premium" ? 10 : 0;
  const planOptions =
    planType === "standard"
      ? [
          "Toutes les fonctionnalités du l'abonnement gratuit",
          "Transfert par date puis format",
          "Transfert par format puis date",
          "Transfert par localisation",
          "Transfert par marque et modèle de l'appareil",
          "Transfert par ISO",
          "Transfert par distance focale",
          "10 transferts par jour",
          "500 photos par transfert",
        ]
      : planType === "premium"
      ? [
          "Toutes les fonctionnalités",
          "100 transferts par jour",
          "15 000 photos par transfert",
        ]
      : [];

  return (
    <div className="flex justify-center items-center h-screen mx-auto w-1/2">
      <div className="bg-white rounded-lg h-auto w-full mx-20 my-10 p-10">
        {!isLogin ? (
          <div className="flex justify-center items-center">
            <FormLogin
              onLoginSuccess={handleLoginSuccess}
              goTo={`/standard?planType=${planType}`}
            />
          </div>
        ) : (
          <div className="flex justify-center items-center flex-col p-6 bg-white rounded-lg space-y-6">
            <h1 className="text-2xl  font-bold uppercase tracking-wider">
              Résumé de votre achat
            </h1>
            <SubscriptionCard
              planType={planType}
              price={planPrice}
              option={planOptions}
            />
            <Button
              onClick={
                userData?.subscription?.planType !== "free"
                  ? handleUpdatePlan
                  : handleCheckOutSession
              }
              className="w-full py-3 bg-blue-600 text-white font-bold rounded-full hover:bg-blue-700 transition duration-300"
            >
              {userData?.subscription?.planType !== "free"
                ? "Mettre à jour"
                : "Suivant"}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeStandard;
