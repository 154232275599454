import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logoB from "../../images/logo_blanc.png";

const Footer = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [productOpen, setProductOpen] = useState(false);
  const [priceOpen, setPriceOpen] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = (path, hash) => {
    navigate(path);
    setTimeout(() => {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  return (
    <footer className=" text-white p-10 mt-96">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="flex flex-col items-center md:items-start">
            <img src={logoB} alt="" className="mb-4 w-32" />
            <p className="text-center md:text-left mb-4">
              Organiseur de photo léger et puissant, pour que chacun et chacune
              puisse trier n'importe quoi et n'importe où.
            </p>
            {/* <select className="bg-gray-800 text-white p-2 rounded">
              <option value="english">English</option>
              <option value="french">Français</option>
            </select> */}
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setMenuOpen(!menuOpen)}
              className="w-full text-left py-2 focus:outline-none"
            >
              <h3 className="font-bold mb-2">Menu</h3>
            </button>
            {menuOpen && (
              <ul className="pl-4 space-y-2 flex flex-col">
                <Link to="/" onClick={() => handleNavigation("/", "home")}>
                  Accueil
                </Link>
                <Link to="/" onClick={() => handleNavigation("/", "product")}>
                  Produit
                </Link>
                <Link to="/" onClick={() => handleNavigation("/", "price")}>
                  Tarifs
                </Link>
                <Link
                  to="/contact"
                  onClick={() => handleNavigation("/", "contact")}
                >
                  Contact
                </Link>
              </ul>
            )}
          </div>
          <div className="hidden md:flex flex-col">
            <h3 className="font-bold mb-4">Menu</h3>
            <ul className="space-y-2 flex flex-col">
              <Link to="/" onClick={() => handleNavigation("/", "home")}>
                Accueil
              </Link>
              <Link to="/" onClick={() => handleNavigation("/", "product")}>
                Produit
              </Link>
              <Link to="/" onClick={() => handleNavigation("/", "price")}>
                Tarifs
              </Link>
              <Link
                to="/contact"
                onClick={() => handleNavigation("/", "contact")}
              >
                Contact
              </Link>
            </ul>
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setProductOpen(!productOpen)}
              className="w-full text-left py-2 focus:outline-none"
            >
              <h3 className="font-bold mb-2">Produit</h3>
            </button>
            {productOpen && (
              <ul className="pl-4 space-y-2 flex  flex-col">
                <Link to="/" onClick={() => handleNavigation("/", "home")}>
                  Fonctionnalités
                </Link>
                <Link to="/" onClick={() => handleNavigation("/", "product")}>
                  Intégrations
                </Link>

                <Link
                  to="/contact"
                  onClick={() => handleNavigation("/", "contact")}
                >
                  FAQ
                </Link>
              </ul>
            )}
          </div>
          <div className="hidden md:flex flex-col">
            <h3 className="font-bold mb-4">Produit</h3>
            <ul className="space-y-2 flex flex-col">
              <Link to="/" onClick={() => handleNavigation("/", "product")}>
                Fonctionnalités
              </Link>

              <Link
                to="/contact"
                onClick={() => handleNavigation("/", "contact")}
              >
                FAQ
              </Link>
            </ul>
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setPriceOpen(!priceOpen)}
              className="w-full text-left py-2 focus:outline-none"
            >
              <h3 className="font-bold mb-2">Tarifs</h3>
            </button>
            {priceOpen && (
              <ul className="pl-4 space-y-2">
                <li>
                  <Link
                    to="/organize"
                    onClick={() => handleNavigation("/", "")}
                  >
                    Gratuit
                  </Link>
                </li>

                <li>
                  <Link to="/" onClick={() => handleNavigation("/", "price")}>
                    Standard
                  </Link>
                </li>
                <li>
                  <Link to="/" onClick={() => handleNavigation("/", "price")}>
                    Premium
                  </Link>
                </li>
              </ul>
            )}
          </div>
          <div className="hidden md:flex flex-col">
            <h3 className="font-bold mb-4">Tarifs</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/organize" onClick={() => handleNavigation("/", "")}>
                  Gratuit
                </Link>
              </li>
              <li>
                <Link to="/" onClick={() => handleNavigation("/", "price")}>
                  Standard
                </Link>
              </li>
              <li>
                <Link to="/" onClick={() => handleNavigation("/", "price")}>
                  Premium
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="text-xs   mt-10 text-center md:text-left border-t border-gray-700 pt-6 flex  justify-center items-center">
          <div className="flex flex-col md:flex-row justify-center md:justify-start space-y-2 md:space-y-0 md:space-x-4 mt-4 ">
            <a
              href="/terms-and-conditions"
              target="_blank"
              className="hover:underline"
            >
              Conditions d'utilisation
            </a>
            <a
              href="/privacy-policy"
              target="_blank"
              className="hover:underline"
            >
              Politique de confidentialité
            </a>
            <a
              href="/cookies-policy"
              target="_blank"
              className="hover:underline"
            >
              Politique de cookie
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
