import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLock,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "./AuthProvider";
import { login } from "../../services/auth.services";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import ButtonComponent from "../elements/Button";

const FormLogin = ({ onLoginSuccess, goTo }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false); // État pour gérer la visibilité du mot de passe

  const { handleLogin } = useAuth(); // Get handleLogin from context
  const location = useLocation();
  let from;

  if (goTo) {
    from = goTo;
  } else {
    from = location.pathname;
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await login(formData);
      console.log(response);
      handleLogin(response.data.token, from);
      Swal.fire({
        icon: "success",
        title: "Connexion réussie !",
        showConfirmButton: false,
        timer: 1500,
      });

      if (onLoginSuccess) {
        onLoginSuccess(); // Move to step 3 after successful login
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Échec de la connexion !",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg"
    >
      <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
        Connexion
      </h2>
      <div className="mb-4">
        <label htmlFor="email" className="block text-gray-600 mb-2">
          Email
        </label>
        <div className="flex items-center border border-gray-300 rounded-md">
          <FontAwesomeIcon icon={faEnvelope} className="ml-3 text-gray-400" />
          <input
            type="email"
            name="email"
            placeholder="Email"
            onChange={handleChange}
            className="w-full p-2.5 outline-none"
            required
          />
        </div>
      </div>
      <div className="mb-6">
        <label htmlFor="password" className="block text-gray-600 mb-2">
          Mot de passe
        </label>
        <div className="flex items-center border border-gray-300 rounded-md">
          <FontAwesomeIcon icon={faLock} className="ml-3 text-gray-400" />
          <input
            type={showPassword ? "text" : "password"} // Bascule entre texte et mot de passe
            name="password"
            placeholder="Mot de passe"
            onChange={handleChange}
            className="w-full p-2.5 outline-none"
            required
          />
          <button
            type="button"
            className="ml-3 p-2"
            onClick={() => setShowPassword(!showPassword)} // Bascule la visibilité
          >
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
          </button>
        </div>
      </div>
      <ButtonComponent
        type="submit"
        className="w-full py-2 px-4 bg-black text-white rounded-md "
      >
        Connexion
      </ButtonComponent>
      <p className="mt-4 text-center text-gray-600">
        Pas de compte ?{" "}
        <a href="/register" className="text-black  hover:underline">
          Inscrivez-vous ici
        </a>
      </p>
      <p className="mt-4 text-center text-gray-600">
        mot de passe oublié ?{" "}
        <a href="/forgot-password" className="text-black  hover:underline">
          Réinitialiser votre mot de passe
        </a>
      </p>
    </form>
  );
};

export default FormLogin;
