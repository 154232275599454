import React, { useState, useEffect } from "react";
import {
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
} from "../../images/filter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Button from "../elements/Button";
import ButtonComponent from "../elements/Button";
import { useAuth } from "../auth/AuthProvider";
import { getUserById } from "../../services/user.services";
import { useNavigate } from "react-router-dom";

const ModalSelectFilters = ({ isOpen, onClose, onConfirm, plan }) => {
  const [sortOption, setSortOption] = useState("format");
  const [deleteSource, setDeleteSource] = useState(false);
  const [allowMail, setAllowMail] = useState(false);
  const [shortOptionImage, setShortOptionImage] = useState("");
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const navigate = useNavigate();

  const { userData } = useAuth();

  const filterAllowed = {
    free: ["simple", "format", "date"],
    standard: [
      "simple",
      "format",
      "date",
      "formatThenDate",
      "dateThenFormat",
      "location",
      "makeModel",
      "focalLength",
      "iso",
    ],
    premium: [
      "simple",
      "format",
      "date",
      "formatThenDate",
      "dateThenFormat",
      "location",
      "makeModel",
      "focalLength",
      "iso",
      // "ia",
    ],
  };

  const filtersPossibles = [
    {
      id: "simple",
      label: "Transfert simple",
      info: "Déplace les photos sans appliquer de tri spécifique.",
      img: img1,
    },
    {
      id: "format",
      label: "Organisation par format",
      info: "Classe les photos en fonction de leur format (JPEG, PNG, etc.) et crée un dossier pour chaque type de fichier.",
      img: img2,
    },
    {
      id: "date",
      label: "Organisation par date",
      info: "Classe les photos par date de création, avec un dossier séparé pour chaque journée.",
      img: img3,
    },
    {
      id: "formatThenDate",
      label: "Format puis date",
      info: "Classe les photos d'abord par format, puis par date à l'intérieur de chaque dossier de format.",
      img: img4,
    },
    {
      id: "dateThenFormat",
      label: "Date puis format",
      info: "Classe les photos d'abord par date, puis par format à l'intérieur de chaque dossier de date.",
      img: img5,
    },
    {
      id: "location",
      label: "Organisation par localisation",
      info: "Classe les photos en fonction de l'endroit où elles ont été prises.",
      img: img6,
    },
    {
      id: "makeModel",
      label: "Organisation par appareil",
      info: "Classe les photos par marque et modèle de l'appareil photo utilisé.",
      img: img7,
    },
    {
      id: "focalLength",
      label: "Organisation par distance focale",
      info: "Classe les photos en fonction de la distance focale utilisée.",
      img: img8,
    },
    {
      id: "iso",
      label: "Organisation par ISO",
      info: "Classe les photos selon les paramètres ISO appliqués.",
      img: img9,
    },
    // {
    //   id: "ia",
    //   label: "Organisation intelligente (IA)",
    //   info: "Utilise l'intelligence artificielle pour organiser les photos de manière optimale.",
    //   img: img10,
    // },
  ];

  useEffect(() => {
    const selectedFilter = filtersPossibles.find(
      (filter) => filter.id === sortOption
    );
    setShortOptionImage(selectedFilter ? selectedFilter.img : "");
  }, [sortOption]);

  const handleConfirm = () => {
    onConfirm({ sortOption, deleteSource, allowMail });
  };

  const handleFilterClick = (filter) => {
    const isDisabled = !filterAllowed[plan].includes(filter.id);
    if (isDisabled) {
      setShowUpgradeModal(true);
    } else {
      setSortOption(filter.id);
    }
  };

  const handleUpdatePlan = () => {
    const planofUser = userData?.subscription?.planType;
    let planType = "";
    if (!planofUser) return;
    if (planofUser === "free") {
      planType = "standard";
      navigate("/standard?planType=" + planType);
    } else if (planofUser === "standard") {
      planType = "premium";
      navigate("/standard?planType=" + planType);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black text-black bg-opacity-75 flex items-center justify-center z-50 w-full h-full">
      <div className="relative bg-gray-100 rounded-lg p-8 w-3/4 max-w-4xl shadow-lg transform transition-all duration-300">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 p-2 rounded-full focus:outline-none"
        >
          <FontAwesomeIcon icon={faXmark} color="black" />
        </button>

        <div className="flex justify-center items-center mb-4">
          <h1 className="text-5xl text-black mb-2">
            Choisissez une méthode d'organisation
          </h1>
        </div>

        <div className="flex space-x-4">
          <div className="flex flex-col gap-2 w-2/5">
            {filtersPossibles.map((filter) => {
              const isDisabled = !filterAllowed[plan].includes(filter.id);
              return (
                <ButtonComponent
                  type={"normal"}
                  key={filter.id}
                  onClick={() => handleFilterClick(filter)}
                  optionalClassName={`${
                    sortOption === filter.id
                      ? "bg-gray-900 text-white scale-105 transform shadow-lg transition-all duration-300"
                      : "bg-white text-black"
                  } w-full px-4 py-2 text-left rounded ${
                    isDisabled ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={isDisabled}
                >
                  {filter.label}
                </ButtonComponent>
              );
            })}
          </div>
          <div className="shadow-lg flex flex-col justify-center items-center w-3/4 p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-2">
              Détails de l'organisation
            </h3>
            <p className="mb-4 text-left w-full">
              {
                filtersPossibles.find((filter) => filter.id === sortOption)
                  ?.info
              }
            </p>
            {shortOptionImage && (
              <img
                src={shortOptionImage}
                alt="Exemple"
                className="w-full max-h-[26rem] object-contain rounded-lg"
              />
            )}
          </div>
        </div>

        <div className="mt-4">
          <input
            type="checkbox"
            id="deleteSource"
            checked={deleteSource}
            onChange={() => setDeleteSource(!deleteSource)}
            className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
          />
          <label htmlFor="deleteSource" className="ml-2 text-lg">
            Supprimer les fichiers sources après l'organisation
          </label>
        </div>
        {/* <div className="mt-4">
          <input
            type="checkbox"
            id="allowMail"
            checked={allowMail}
            onChange={() => setAllowMail(!allowMail)}
            className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
          />
          <label htmlFor="allowMail" className="ml-2 text-lg">
            Ne pas envoyer un mail récapitulatif
          </label>
        </div> */}
        <div className="mt-8 flex w-1/2 mx-auto space-x-4">
          <Button onClick={onClose}>Annuler</Button>
          <Button onClick={handleConfirm}>Valider</Button>
        </div>
      </div>

      {showUpgradeModal && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-2xl font-bold mb-4">
              Mise à niveau nécessaire
            </h2>
            <p className="mb-4">
              Cette méthode d'organisation est disponible uniquement pour un
              plan supérieur. Souhaitez-vous mettre à niveau votre abonnement ?
            </p>
            <div className="flex justify-end space-x-4">
              <Button onClick={() => setShowUpgradeModal(false)}>
                Annuler
              </Button>
              <Button
                onClick={() => {
                  // setShowUpgradeModal(false);
                  handleUpdatePlan();
                }}
              >
                Mettre à niveau
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalSelectFilters;
