import React from "react";

const Cookies = () => {
  return (
    <div className="p-8 mt-20 text-white max-w-3xl mx-auto ">
      <h1 className="text-3xl font-bold mb-6">Politique de Cookies</h1>

      <p className="mb-4">
        Cette politique de cookies explique comment [Nom de votre entreprise]
        utilise des cookies et des technologies similaires pour reconnaître les
        utilisateurs lorsqu'ils visitent notre site web. Elle explique ce que
        sont ces technologies, pourquoi nous les utilisons, et les droits des
        utilisateurs pour contrôler notre utilisation.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Qu'est-ce qu'un cookie ?</h2>
      <p className="mb-4">
        Les cookies sont de petits fichiers texte stockés sur votre appareil
        lorsque vous visitez un site web. Ils permettent au site de reconnaître
        votre appareil et de mémoriser certaines informations à votre sujet,
        telles que vos préférences ou vos actions passées.
      </p>

      <h2 className="text-2xl font-semibold mb-4">
        Pourquoi utilisons-nous des cookies ?
      </h2>
      <p className="mb-4">
        Nous utilisons des cookies pour diverses raisons, notamment pour :
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Assurer le bon fonctionnement de notre site web.</li>
        <li>
          Mémoriser vos préférences et paramètres pour améliorer votre
          expérience.
        </li>
        <li>
          Analyser le trafic et l'utilisation du site afin de l'améliorer.
        </li>
        <li>
          Vous fournir des publicités pertinentes en fonction de vos intérêts.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4">
        Types de cookies que nous utilisons
      </h2>
      <p className="mb-4">
        Nous utilisons différents types de cookies sur notre site, notamment :
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Cookies strictement nécessaires :</strong> Ces cookies sont
          essentiels pour vous permettre de naviguer sur notre site et
          d'utiliser ses fonctionnalités.
        </li>
        <li>
          <strong>Cookies de performance :</strong> Ces cookies collectent des
          informations sur la façon dont les utilisateurs interagissent avec
          notre site, ce qui nous permet de l'améliorer.
        </li>
        <li>
          <strong>Cookies de fonctionnalité :</strong> Ces cookies permettent de
          mémoriser vos choix (par exemple, votre langue) et d'offrir des
          fonctionnalités personnalisées.
        </li>
        <li>
          <strong>Cookies publicitaires :</strong> Ces cookies sont utilisés
          pour vous montrer des publicités pertinentes basées sur vos intérêts.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4">Gestion des cookies</h2>
      <p className="mb-4">
        Vous pouvez configurer votre navigateur pour bloquer ou supprimer les
        cookies, mais cela peut affecter votre expérience utilisateur sur notre
        site. Vous trouverez des instructions pour gérer les cookies dans les
        navigateurs populaires ci-dessous :
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          <a
            href="https://support.google.com/chrome/answer/95647"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            Google Chrome
          </a>
        </li>
        <li>
          <a
            href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            Mozilla Firefox
          </a>
        </li>
        <li>
          <a
            href="https://support.apple.com/fr-fr/guide/safari/sfri11471/mac"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            Apple Safari
          </a>
        </li>
        <li>
          <a
            href="https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            Microsoft Edge
          </a>
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4">Cookies tiers</h2>
      <p className="mb-4">
        Certains cookies peuvent être placés par des tiers, comme Google
        Analytics, pour collecter des informations sur votre utilisation du site
        et vous montrer des publicités pertinentes. Nous vous recommandons de
        consulter les politiques de confidentialité de ces tiers pour plus
        d'informations.
      </p>

      <h2 className="text-2xl font-semibold mb-4">
        Mises à jour de cette politique
      </h2>
      <p className="mb-4">
        Nous pouvons mettre à jour cette politique de cookies de temps en temps
        pour refléter les changements apportés à nos pratiques. Nous vous
        informerons de toute modification en publiant la nouvelle version sur
        cette page.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Nous contacter</h2>
      <p className="mb-4">
        Si vous avez des questions concernant cette politique de cookies, vous
        pouvez nous contacter à l'adresse suivante :
        contactpicorganzer@gmail.com.
      </p>
    </div>
  );
};

export default Cookies;
