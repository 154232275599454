import EXIF from "exif-js";
import Swal from "sweetalert2";

const useFileOrganizer = () => {
  const getEXIFData = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    return EXIF.readFromBinaryFile(arrayBuffer);
  };

  function convertDMSToDecimal(dmsArray, ref) {
    const degrees = dmsArray[0].numerator / dmsArray[0].denominator;
    const minutes = dmsArray[1].numerator / dmsArray[1].denominator;
    const seconds = dmsArray[2].numerator / dmsArray[2].denominator;

    let decimal = degrees + minutes / 60 + seconds / 3600;

    if (ref === "S" || ref === "W") {
      decimal *= -1;
    }

    return decimal;
  }

  const fetchLocationName = async (exifData, groupBy) => {
    const latitude = convertDMSToDecimal(
      exifData.GPSLatitude,
      exifData.GPSLatitudeRef
    );
    const longitude = convertDMSToDecimal(
      exifData.GPSLongitude,
      exifData.GPSLongitudeRef
    );

    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
      );
      const data = await response.json();

      if (groupBy === "city") {
        return (
          data.address.city ||
          data.address.town ||
          data.address.village ||
          "Unknown"
        );
      } else if (groupBy === "country") {
        return data.address.country || "Unknown";
      } else {
        return "Unknown";
      }
    } catch (error) {
      console.error("Error fetching location name:", error);
      return "Unknown";
    }
  };

  const organizeByLocation = async (
    files,
    targetDir,
    setProgress,
    groupBy = "city"
  ) => {
    let processedFiles = 0;
    const totalFiles = files.length;

    for (const entry of files) {
      const file = await entry.getFile();
      const exifData = await getEXIFData(file);

      let location = "Unknown";
      if (exifData && exifData.GPSLatitude && exifData.GPSLongitude) {
        location = await fetchLocationName(exifData, groupBy);
      }

      let locationFolderHandle = await targetDir.getDirectoryHandle(location, {
        create: true,
      });

      const targetFileHandle = await locationFolderHandle.getFileHandle(
        file.name,
        {
          create: true,
        }
      );
      const writableStream = await targetFileHandle.createWritable();
      await writableStream.write(await file.arrayBuffer());
      await writableStream.close();

      processedFiles += 1;
      setProgress(Math.round((processedFiles / totalFiles) * 100));
    }
  };

  const organizeByMakeAndModel = async (files, targetDir, setProgress) => {
    let processedFiles = 0;
    const totalFiles = files.length;

    for (const entry of files) {
      const file = await entry.getFile();
      const exifData = await getEXIFData(file);

      const make = exifData.Make || "Unknown";
      const model = exifData.Model || "Unknown";
      const folderName = `${make}_${model}`;

      let makeModelFolderHandle = await targetDir.getDirectoryHandle(
        folderName,
        {
          create: true,
        }
      );

      const targetFileHandle = await makeModelFolderHandle.getFileHandle(
        file.name,
        { create: true }
      );
      const writableStream = await targetFileHandle.createWritable();
      await writableStream.write(await file.arrayBuffer());
      await writableStream.close();

      processedFiles += 1;
      setProgress(Math.round((processedFiles / totalFiles) * 100));
    }
  };

  const organizeByFocalLength = async (files, targetDir, setProgress) => {
    let processedFiles = 0;
    const totalFiles = files.length;

    for (const entry of files) {
      const file = await entry.getFile();
      const exifData = await getEXIFData(file);

      const focalLength = exifData.FocalLength
        ? `${exifData.FocalLength}mm`
        : "Unknown";

      let focalLengthFolderHandle = await targetDir.getDirectoryHandle(
        focalLength,
        {
          create: true,
        }
      );

      const targetFileHandle = await focalLengthFolderHandle.getFileHandle(
        file.name,
        { create: true }
      );
      const writableStream = await targetFileHandle.createWritable();
      await writableStream.write(await file.arrayBuffer());
      await writableStream.close();

      processedFiles += 1;
      setProgress(Math.round((processedFiles / totalFiles) * 100));
    }
  };

  const organizeByISO = async (files, targetDir, setProgress) => {
    let processedFiles = 0;
    const totalFiles = files.length;

    for (const entry of files) {
      const file = await entry.getFile();
      const exifData = await getEXIFData(file);

      const iso = exifData.ISOSpeedRatings
        ? `ISO-${exifData.ISOSpeedRatings}`
        : "Unknown";

      let isoFolderHandle = await targetDir.getDirectoryHandle(iso, {
        create: true,
      });

      const targetFileHandle = await isoFolderHandle.getFileHandle(file.name, {
        create: true,
      });
      const writableStream = await targetFileHandle.createWritable();
      await writableStream.write(await file.arrayBuffer());
      await writableStream.close();

      processedFiles += 1;
      setProgress(Math.round((processedFiles / totalFiles) * 100));
    }
  };

  const organizeByFormat = async (files, targetDir, setProgress) => {
    let processedFiles = 0;
    const totalFiles = files.length;

    for (const entry of files) {
      const file = await entry.getFile();
      const ext = file.name.split(".").pop();

      let formatFolderHandle = await targetDir.getDirectoryHandle(ext, {
        create: true,
      });

      const targetFileHandle = await formatFolderHandle.getFileHandle(
        file.name,
        { create: true }
      );
      const writableStream = await targetFileHandle.createWritable();
      await writableStream.write(await file.arrayBuffer());
      await writableStream.close();

      processedFiles += 1;
      setProgress(Math.round((processedFiles / totalFiles) * 100));
    }
  };

  const organizeByDate = async (files, targetDir, setProgress) => {
    let processedFiles = 0;
    const totalFiles = files.length;

    for (const entry of files) {
      const file = await entry.getFile();
      const date = new Date(file.lastModified);
      const dateStr = date.toISOString().split("T")[0];

      let dateFolderHandle = await targetDir.getDirectoryHandle(dateStr, {
        create: true,
      });

      const targetFileHandle = await dateFolderHandle.getFileHandle(file.name, {
        create: true,
      });
      const writableStream = await targetFileHandle.createWritable();
      await writableStream.write(await file.arrayBuffer());
      await writableStream.close();

      processedFiles += 1;
      setProgress(Math.round((processedFiles / totalFiles) * 100));
    }
  };

  const organizeByFormatThenDate = async (files, targetDir, setProgress) => {
    let processedFiles = 0;
    const totalFiles = files.length;

    for (const entry of files) {
      const file = await entry.getFile();
      const ext = file.name.split(".").pop();
      const date = new Date(file.lastModified);
      const dateStr = date.toISOString().split("T")[0];

      let formatFolderHandle = await targetDir.getDirectoryHandle(ext, {
        create: true,
      });
      let dateFolderHandle = await formatFolderHandle.getDirectoryHandle(
        dateStr,
        { create: true }
      );

      const targetFileHandle = await dateFolderHandle.getFileHandle(file.name, {
        create: true,
      });
      const writableStream = await targetFileHandle.createWritable();
      await writableStream.write(await file.arrayBuffer());
      await writableStream.close();

      processedFiles += 1;
      setProgress(Math.round((processedFiles / totalFiles) * 100));
    }
  };

  const organizeByDateThenFormat = async (files, targetDir, setProgress) => {
    let processedFiles = 0;
    const totalFiles = files.length;

    for (const entry of files) {
      const file = await entry.getFile();
      const ext = file.name.split(".").pop();
      const date = new Date(file.lastModified);
      const dateStr = date.toISOString().split("T")[0];

      let dateFolderHandle = await targetDir.getDirectoryHandle(dateStr, {
        create: true,
      });
      let formatFolderHandle = await dateFolderHandle.getDirectoryHandle(ext, {
        create: true,
      });

      const targetFileHandle = await formatFolderHandle.getFileHandle(
        file.name,
        { create: true }
      );
      const writableStream = await targetFileHandle.createWritable();
      await writableStream.write(await file.arrayBuffer());
      await writableStream.close();

      processedFiles += 1;
      setProgress(Math.round((processedFiles / totalFiles) * 100));
    }
  };

  const organizeBySimpleTransfert = async (files, targetDir, setProgress) => {
    let processedFiles = 0;
    const totalFiles = files.length;

    for (const entry of files) {
      const file = await entry.getFile();

      let targetFileHandle = await targetDir.getFileHandle(file.name, {
        create: true,
      });

      const writableStream = await targetFileHandle.createWritable();
      await writableStream.write(await file.arrayBuffer());
      await writableStream.close();

      processedFiles += 1;
      setProgress(Math.round((processedFiles / totalFiles) * 100));
    }
  };

  const removeSourceFiles = async (files, sourceDir) => {
    for (const entry of files) {
      await sourceDir.removeEntry(entry.name);
    }
  };

  const getNumberFilesInSourceDir = async (sourceDir) => {
    const files = [];
    for await (const entry of sourceDir.values()) {
      if (entry.kind === "file") {
        files.push(entry);
      }
    }
    return files.length;
  };
  const checkFormatFiles = async (sourceDir) => {
    const files = [];
    const supportedFormats = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "bmp",
      "tiff",
      "webp",
      "cr2",
      "avif",
      "dng",
      "tif",
      "nef",
      "raw",
      "mp4",
      "mov",
      "aae",
      "heic",
    ];

    for await (const entry of sourceDir.values()) {
      if (entry.kind === "file") {
        const ext = entry.name.split(".").pop().toLowerCase();
        if (supportedFormats.includes(ext)) {
          files.push(entry);
        } else {
          Swal.fire({
            icon: "warning",
            title: "Format non pris en charge",
            text: `Le fichier "${entry.name}" n'est pas un format d'image pris en charge.`,
          });
          return false; // Retourne `false` si un fichier non pris en charge est trouvé
        }
      }
    }
    console.log(files);
    return true; // Retourne `true` si tous les fichiers sont pris en charge
  };
  const selectDirectory = async (setter) => {
    try {
      const directoryHandle = await window.showDirectoryPicker();
      setter(directoryHandle);
    } catch (error) {
      console.error("Erreur lors de la sélection du dossier:", error);
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: "Erreur lors de la sélection du dossier.",
      });
    }
  };

  const getFilesFromDirectory = async (directory) => {
    console.log(directory);
    const files = [];
    for await (const entry of directory.values()) {
      if (entry.kind === "file") {
        files.push(entry);
      }
    }

    console.log(files);
    return files;
  };

  const organizeFilesByOption = async (
    files,
    sortOption,
    targetDir,
    setProgress,
    groupBy = null
  ) => {
    const organizeFunctions = {
      location: (files, targetDir, setProgress) =>
        organizeByLocation(files, targetDir, setProgress, groupBy),
      makeModel: organizeByMakeAndModel,
      focalLength: organizeByFocalLength,
      iso: organizeByISO,
      format: organizeByFormat,
      date: organizeByDate,
      formatThenDate: organizeByFormatThenDate,
      dateThenFormat: organizeByDateThenFormat,
      simpleTransfert: organizeBySimpleTransfert,
    };

    const organizeFunction = organizeFunctions[sortOption];
    if (organizeFunction) {
      await organizeFunction(files, targetDir, setProgress);
    }
  };

  return {
    selectDirectory,
    getNumberFilesInSourceDir,
    organizeFilesByOption,
    removeSourceFiles,
    getFilesFromDirectory,
    checkFormatFiles,
  };
};

export default useFileOrganizer;
