import React from "react";

const ModalTransfert = ({ isLoading, progress }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 w-full h-full">
      <div className="bg-white rounded-lg p-8 w-11/12 max-w-md mx-auto shadow-lg transform transition-all duration-300">
        <div className="text-black text-center mb-6">
          <h1 className="text-2xl font-bold mb-2">Transfert en cours</h1>
          <p className="text-sm">Veuillez ne pas fermer cette fenêtre</p>
        </div>
        <div className="w-full bg-gray-300 rounded-full h-4 shadow-inner">
          <div
            className="bg-gray-600 h-4 rounded-full shadow-md transition-all duration-300"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <div className="text-gray-700 mt-4 text-center text-lg font-semibold">
          {progress}%
        </div>
      </div>
    </div>
  );
};

export default ModalTransfert;
