import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="p-6 max-w-4xl mx-auto text-left text-white">
      <h1 className="text-3xl font-bold mb-6">
        Conditions Générales d'Utilisation
      </h1>

      <h2 className="text-2xl font-semibold mt-4">1. Introduction</h2>
      <p className="mb-4">
        Bienvenue sur PicOrganizer. Ces conditions générales d'utilisation
        régissent l'utilisation de notre service en ligne de tri et
        d'organisation de photos. En accédant à notre site et en utilisant nos
        services, vous acceptez ces conditions.
      </p>

      <h2 className="text-2xl font-semibold mt-4">
        2. Accès et Utilisation des Services
      </h2>
      <p className="mb-4">
        PicOrganizer vous permet de trier vos photos selon divers critères
        (date, nom, format, etc.). Le service comprend la création, la copie, le
        renommage et la suppression de fichiers sur votre appareil. Vous êtes
        responsable de la sauvegarde de vos fichiers avant d'utiliser la
        fonctionnalité de suppression après tri.
      </p>

      <h2 className="text-2xl font-semibold mt-4">
        3. Création de Compte et Sécurité
      </h2>
      <p className="mb-4">
        Pour utiliser nos services, vous devez créer un compte en fournissant
        des informations personnelles telles que votre nom, prénom, adresse
        e-mail et mot de passe. Vous êtes responsable de la sécurité de votre
        compte et de toutes les activités qui y sont associées. Vous pouvez
        modifier ou supprimer vos informations personnelles à tout moment via
        les paramètres de votre compte.
      </p>

      <h2 className="text-2xl font-semibold mt-4">
        4. Abonnements et Paiements
      </h2>
      <p className="mb-4">Nous proposons trois formules d'abonnement :</p>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>Gratuit :</strong> Sans frais
        </li>
        <li>
          <strong>Standard :</strong> 5 euros par mois
        </li>
        <li>
          <strong>Premium :</strong> 10 euros par mois
        </li>
      </ul>
      <p className="mb-4">
        Les paiements sont traités via Stripe. Si vous souhaitez annuler votre
        abonnement, vous pouvez le faire à tout moment. Les remboursements
        seront accordés uniquement si la demande est justifiée.
      </p>

      <h2 className="text-2xl font-semibold mt-4">
        5. Politique de Remboursement
      </h2>
      <p className="mb-4">
        Nous offrons des remboursements sur demande, à condition que la demande
        soit justifiée (par exemple, erreur de facturation, service non conforme
        à la description). Nous nous réservons le droit de refuser les
        remboursements pour des raisons non justifiées.
      </p>

      <h2 className="text-2xl font-semibold mt-4">6. Données Personnelles</h2>
      <p className="mb-4">
        Nous collectons des informations personnelles pour créer et gérer votre
        compte. Vous pouvez demander la suppression de votre compte ou la
        modification de vos données personnelles à tout moment. Vos données sont
        protégées par des mesures de sécurité appropriées.
      </p>

      <h2 className="text-2xl font-semibold mt-4">
        7. Propriété Intellectuelle
      </h2>
      <p className="mb-4">
        La logique de tri des photos, ainsi que tout le contenu, les images, les
        logos et autres éléments du site, sont protégés par des droits de
        propriété intellectuelle. Toute utilisation ou reproduction non
        autorisée est interdite.
      </p>

      <h2 className="text-2xl font-semibold mt-4">
        8. Limitation de Responsabilité
      </h2>
      <p className="mb-4">
        PicOrganizer décline toute responsabilité en cas de perte de données
        résultant de l'utilisation de notre service, en particulier dans le cas
        de la suppression de photos après tri. Il est fortement recommandé de
        sauvegarder vos fichiers avant d'utiliser cette fonctionnalité.
      </p>

      <h2 className="text-2xl font-semibold mt-4">
        9. Modifications des Conditions
      </h2>
      <p className="mb-4">
        Nous nous réservons le droit de modifier ces conditions générales
        d'utilisation à tout moment. Les utilisateurs seront informés des
        modifications via une notification sur le site ou par e-mail. La
        poursuite de l'utilisation de nos services après modification implique
        l'acceptation des nouvelles conditions.
      </p>

      <h2 className="text-2xl font-semibold mt-4">
        10. Juridiction et Résolution des Litiges
      </h2>
      <p className="mb-4">
        Ces conditions générales d'utilisation sont régies par la loi française.
        En cas de litige, une médiation sera proposée avant toute action en
        justice. En cas d'échec de la médiation, les tribunaux français seront
        compétents pour résoudre le conflit.
      </p>

      <h2 className="text-2xl font-semibold mt-4">11. Contact</h2>
      <p className="mb-4">
        Pour toute question ou réclamation concernant ces conditions générales
        d'utilisation, veuillez nous contacter via le formulaire de contact sur
        notre site ou à l'adresse e-mail suivante :
        contactpicorganizer@gmail.com. Nous nous engageons à répondre sous 72
        heures.
      </p>
    </div>
  );
};

export default TermsAndConditions;
